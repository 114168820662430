<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-end" style="margin-right:10px">
        <b-button-group>
          <b-button :disabled="disableControls" variant="primary" @click="SaveAll" pill style="margin-right:10px">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/> Guardar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/>
            </div>
          </b-button>
          <b-button :disabled="disableControls" variant="warning" @click="Register" pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Registrar">
                <b-icon icon="plus-circle"/> Registrar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Registrar">
                <b-icon icon="plus-circle"/>
            </div>
          </b-button>
        </b-button-group>
      </div>
      <div class="row">
        <div class="col-12" style="margin-top:20px">
          <a-table :columns="tableObj.columns"
             :data-source="tableObj.data"
             :pagination="tableObj.pagination"
             :loading="tableObj.loading"
             @change="FetchTable"
             :row-key="record => record.id"
          >
            <template slot="crushingType" slot-scope="record">
              <small>
                <strong>
                  {{ record.auxiliar_masters.code }} - {{ record.auxiliar_masters.name }}
                </strong>
              </small>
            </template>
            <template slot="column2" slot-scope="record">
              <small>
                <strong>
                  {{ record.base_cone }}
                </strong>
              </small>
            </template>
            <template slot="column3" slot-scope="record">
              <small>
                <strong>
                  {{ record.hight_cone }}
                </strong>
              </small>
            </template>
            <template slot="column4" slot-scope="record">
              <small>
                <strong>
                  {{ record.calculation_tons }}
                </strong>
              </small>
            </template>
            <template slot="column5" slot-scope="record">
              <small>
                <strong>
                  {{ record.volume }}
                </strong>
              </small>
            </template>
            <template slot="column6" slot-scope="record">
              <small>
                <strong>
                  {{ record.discharge_tons }}
                </strong>
              </small>
            </template>
            <template slot="column7" slot-scope="record">
              <small>
                <strong>
                  {{ record.debeader }}
                </strong>
              </small>
            </template>
          </a-table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-end align-self-center">
            <b-modal size="lg"
            :title="'Agregar Residuo Llantas'"
            v-model="showModal" ref="modal-Form">
              <my-spinner :load="spinnerLoad"/>
              <b-container fluid v-show="!spinnerLoad">
                <a-form-model :rules="formRules" :model="resourceObj.form" ref="rule-Form"
                    layout="vertical" :wrapper-col="{ span: 24 }">
                  <a-form-model-item label="Trituradoras" ref="auxiliar_master_id"  prop="auxiliar_master_id" >
                    <a-select v-model="resourceObj.form.auxiliar_master_id" allowClear
                            placeholder="Seleccionar tipo de Trituradora" show-search>
                      <a-select-option  v-for="option in crushings" :key="option.id" :value="option.id" >
                        {{option.code}}-{{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="Base Cono (m):"
                                        class="mx-1"
                                        ref="base_cone"
                                        prop="base_cone"
                  >
                    <a-input-number v-model="resourceObj.form.base_cone"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Altura Cono (m)"
                                        class="mx-1"
                                        ref="hight_cone"
                                        prop="hight_cone"
                  >
                    <a-input-number v-model="resourceObj.form.hight_cone"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Toneladas calculadas"
                                        class="mx-1"
                                        ref="calculation_tons"
                                        prop="calculation_tons"
                  >
                    <a-input-number v-model="resourceObj.form.calculation_tons"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Volumen (m3)"
                                        class="mx-1"
                                        ref="volume"
                                        prop="volume"
                  >
                    <a-input-number v-model="resourceObj.form.volume"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Toneladas descargadas"
                                        class="mx-1"
                                        ref="discharge_tons"
                                        prop="discharge_tons"
                  >
                    <a-input-number v-model="resourceObj.form.discharge_tons"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Llantas pasadas por Debeader"
                                        class="mx-1"
                                        ref="debeader"
                                        prop="debeader"
                  >
                    <a-input-number v-model="resourceObj.form.debeader"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <div class="row justify-content-end">
                    <b-button-group>
                      <b-button  variant="primary" @click="SendTire" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/> Guardar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/>
                        </div>
                      </b-button>
                    </b-button-group>
                  </div>
                </a-form-model>
              </b-container>
              <template v-slot:modal-footer>
                <simple-modal-footer @ClickEvent="closeSampleModal"/>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
export default {
  name: 'wheelCrushing',
  mixins: [fractalMixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
    steps: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      disableControls: false,
      showModal: false,
      spinnerLoad: false,
      crushings: [],
      resourceObj: {
        resourceType: 'tireCrushings',
        form: {
          id: '',
          formulation_id: undefined,
          auxiliar_master_id: undefined,
          base_cone: undefined,
          hight_cone: undefined,
          calculation_tons: undefined,
          volume: undefined,
          discharge_tons: undefined,
          debeader: '',
        },
      },
      updateSteps: {
        resourceType: 'stepFormulations',
        form: {
          id: parseInt(this.formulationId),
          formulation_id: parseInt(this.formulationId),
          tire_crushings_dated_at: '',
          tire_crushings_responsible: '',
          tire_crushings_status: '',
          tire_crushings_block: false,
        },
      },
      formRules: {
        auxiliar_master_id: [{ required: true, message: 'La trituradora es obligatoria.', trigger: 'blur' }],
        base_cone: [{ required: true, message: 'La base del cono es obligatorio.', trigger: 'blur' }],
        hight_cone: [{ required: true, message: 'La altura del cono es obligatorio', trigger: 'blur' }],
        calculation_tons: [{ required: true, message: 'Las toneladas calculadas es obligatorio', trigger: 'blur' }],
        volume: [{ required: true, message: 'El Volumen es obligatorio', trigger: 'blur' }],
        discharge_tons: [{ required: true, message: 'Las toneladas descargadas es obligatorio', trigger: 'blur' }],
        debeader: [{ required: true, message: 'El debeader es obligatorio', trigger: 'blur' }],
      },
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Trituradora',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'crushingType' },
            width: '15%',
          },
          {
            title: 'Base Cono (m)',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column2' },
          },
          {
            title: 'Altura del Cono (m)',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column3' },
          },
          {
            title: 'TON Cálculo',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column4' },
          },
          {
            title: 'Volumen (m3)',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column5' },
          },
          {
            title: 'TON descargadas',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column6' },
          },
          {
            title: 'Debeader',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column7' },
          },
        ],
      },
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagEvent, 'tireCrushings', this.tableObj, params).then(
        () => {
        },
      )
    },
    SaveAll() {
      this.closeCrushingMessage('Esta acción concluirá el proceso de trituración de llantas. ¿Desea Continuar?')
    },
    async Register() {
      this.spinnerLoad = true
      this.showModal = true
      await this.fetchCombos().then(
        () => {
          this.spinnerLoad = false
          this.resourceObj.form = {
            formulation_id: parseInt(this.formulationId),
            auxiliar_master_id: undefined,
            base_cone: undefined,
            hight_cone: undefined,
            calculation_tons: undefined,
            volume: undefined,
            discharge_tons: undefined,
            debeader: '',
          }
        },
      )
    },
    closeSampleModal() {
      this.showModal = false
    },
    async SendTire() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          console.log('Tabla: ', this.resourceObj.form)
          this.spinnerLoad = true
          await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.closeSampleModal()
    },
    async fetchCombos() {
      const arr = [
        {
          path: '/fetch/auxiliarMasters',
        },
      ]
      const [crushings] = await this.GetConcurrencyResources(arr)
      this.crushings = crushings
    },
    closeCrushingMessage(message) {
      Modal.confirm({
        title: '¡Concluyendo proceso de Trituración!',
        content: message,
        okText: 'Ok',
        cancelText: 'Cancelar',
        centered: true,
        zIndex: 3000,
        onOk: () => { this.updateStep() },
      })
    },
    checkSteps() {
      if (this.steps.data.length > 0) {
        if (this.steps.data[0].tire_crushings_status === 'Concluido') {
          this.disableControls = true
        } else {
          this.disableControls = false
        }
        this.updateSteps.form.id = parseInt(this.formulationId)
        this.updateSteps.form.formulation_id = this.formulationId
        this.updateSteps.form.tire_crushings_dated_at = ''
        this.updateSteps.form.tire_crushings_responsible = ''
        this.updateSteps.form.tire_crushings_status = this.steps.data[0].tire_crushings_status
        this.updateSteps.form.tire_crushings_block = this.steps.data[0].tire_crushings_block
      }
    },
    async updateStep() {
      this.updateSteps.form.tire_crushings_status = 'Concluido'
      this.updateSteps.form.tire_crushings_block = true
      this.updateSteps.form.tire_crushings_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
      await this.PutResourcev2(this.updateSteps, this.SuccessCallback, () => { this.spinnerLoad = false })
      if (this.updateSteps.form.tire_crushings_status === 'Concluido') {
        this.disableControls = true
      } else {
        this.disableControls = false
      }
      this.$emit('onChangeValue')
    },
  },
  async mounted() {
    await this.FetchTable()
    this.checkSteps()
  },
}
</script>
