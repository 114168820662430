<template>
  <a-row>
    <a-tabs defaultActiveKey="1" type="card" size="large">
      <a-tab-pane key="1">
        <span slot="tab">Solicitud de Análisis</span>
        <div class="card">
          <div class="card-body text-center">
            <a-row type="flex" justify="end" class="mb-4" >
              <a-col :sm="{ span: 6 }" :md="{ span:4}" v-if="!locked">
                <b-button pill variant="primary" @click="OpenSampleModal()">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Solicitar Análisis">
                    <b-icon icon="plus"/> Solicitar Análisis
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Solicitar Análisis">
                    <b-icon icon="plus"/>
                  </div>
                </b-button>
              </a-col>
              <a-col :sm="{ span: 6 }" :md="{ span:4}">
                <b-button pill variant="info" @click="OpenStepsModal()">
                 <div class="d-none d-md-block" v-b-tooltip.hover title="Información">
                   <b-icon icon="info-circle"/> Información
                 </div>
                 <div class="d-md-none" v-b-tooltip.hover title="Información">
                   <b-icon icon="info-circle"/>
                 </div>
                </b-button>
              </a-col>
            </a-row>
            <a-row>
              <a-table :scroll="{x:1300}" bordered
                :dataSource="tableObj.data" :columns="tableObj.columns"
                :loading="tableObj.loading" :pagination="tableObj.pagination"
                :row-key="record => record.id" @change="FetchTable">
                <template slot="serviceDetail" slot-scope="row">
                  <div class="text-center">
                    <small>
                      <strong>Centro de Trabajo:</strong>
                    </small>
                    <small>
                      <strong>{{ row.work_centers.code }}</strong> -
                      {{ row.work_centers.name }}
                    </small>
                  </div>
                  <div class="text-center">
                    <small>
                      <strong>Cliente / Solicitante:</strong>
                    </small>
                    <small>{{ row.lab_customer }}</small>
                  </div>
                  <hr class="bg-primary">
                  <div class="text-center">
                    <small>
                      <strong>No.Servicio:</strong>
                    </small>
                    <small>
                      <strong>{{ row.invoice_code }}</strong>
                    </small>
                  </div>
                  <div class="text-center">
                    <small>
                      <strong>Corriente:</strong>
                    </small>
                    <small>
                      {{ row.currents.name }}
                    </small>
                  </div>
                  <div class="text-center">
                    <small>
                      <strong>Material:</strong>
                    </small>
                    <small>
                      {{ row.sample_material }}
                    </small>
                  </div>
                  <div class="text-center">
                    <small>
                      <strong>Tipo de Contenedor:</strong>
                    </small>
                    <small>{{ row.container_types.name }}</small>
                  </div>
                </template>
                <template slot="sampleDetail" slot-scope="row">
                  <div class="text-center">
                    <small>
                      <strong>Tipo de Ingreso:</strong>
                    </small>
                    <small> {{ row.sample_type }}</small>
                  </div>
                  <div class="text-center">
                    <small>
                      <strong>Cantidad de Muestras tomada:</strong>
                    </small>
                    <small> {{ row.ums.name }}</small>
                    <small> <strong>{{ row.quantity }} </strong></small>
                  </div>
                  <hr class="bg-primary">
                  <div class="text-center">
                    <small>
                      <strong>Fecha de recepción:</strong>
                    </small>
                    <small> {{ moment(row.request_dated_at).format('DD-MM-YYYY') }}</small>
                  </div>
                </template>
                <template slot="status" slot-scope="row">
                  <!-- Creación del Perfil -->
                  <div class="row text-center border border-primary">
                    <div class="col-12">
                      <small><strong>Creación del Perfil:</strong></small>
                      <small :class="receipt_sample_date_status_class2(row.receipt_sample_date.profile_status)">
                        {{row.receipt_sample_date.profile_status}}
                      </small>
                    </div>
                    <div class="col-12">
                      <small><strong>Fecha:</strong></small>
                      <small> {{moment(row.receipt_sample_date.profile_dated_at).format('DD/MM/YY')}}</small>
                    </div>
                    <div class="col-12">
                      <small><strong>Responsable:</strong></small>
                      <small> {{row.receipt_sample_date.profile_responsible}}</small>
                    </div>
                  </div>
                  <!-- Plan de Muestreo -->
                  <div class="row text-center border border-primary">
                    <div class="col-12">
                      <small><strong>Plan de Muestreo:</strong></small>
                      <small :class="receipt_sample_date_status_class2(row.receipt_sample_date.receipt_status)">
                        {{row.receipt_sample_date.receipt_status}}
                      </small>
                    </div>
                    <div class="col-12">
                      <small><strong>Fecha:</strong></small>
                      <small> {{moment(row.receipt_sample_date.receipt_dated_at).format('DD/MM/YY')}}</small>
                    </div>
                    <div class="col-12">
                      <small><strong>Responsable:</strong></small>
                      <small> {{row.receipt_sample_date.receipt_responsible}}</small>
                    </div>
                  </div>
                  <!-- Inspección Visual -->
                  <div class="row text-center border border-primary">
                    <div class="col-12">
                      <small><strong>Inspección Visual:</strong></small>
                      <small :class="receipt_sample_date_status_class2(row.receipt_sample_date.visual_inspections_status)">
                        {{row.receipt_sample_date.visual_inspections_status}}
                      </small>
                    </div>
                    <div class="col-12">
                      <small><strong>Fecha:</strong></small>
                      <small>{{moment(row.receipt_sample_date.visual_inspections_dated_at).format('DD/MM/YY')}}</small>
                    </div>
                    <div class="col-12">
                      <strong>Responsable:</strong>
                      <span>{{row.receipt_sample_date.visual_inspections_responsible}}</span>
                    </div>
                  </div>
                  <!-- Asignación de Almacén -->
                  <div class="row text-center border border-primary">
                    <div class="col-12">
                      <small><strong>Asignación de Almacén:</strong></small>
                      <small :class="receipt_sample_date_status_class2(row.receipt_sample_date.set_warehouse_status)">
                        {{row.receipt_sample_date.set_warehouse_status}}
                      </small>
                    </div>
                    <div class="col-12">
                      <small><strong>Fecha:</strong></small>
                      <small>{{moment(row.receipt_sample_date.set_warehouse_dated_at).format('DD/MM/YY')}}</small>
                    </div>
                    <div class="col-12">
                      <strong>Responsable:</strong>
                      <span>{{row.receipt_sample_date.set_warehouse_responsible}}</span>
                    </div>
                  </div>
                  <!-- Asignación de Análisis -->
                  <div class="row text-center border border-primary">
                    <div class="col-12">
                      <small><strong>Asignación de Análisis:</strong></small>
                      <small :class="receipt_sample_date_status_class2(row.receipt_sample_date.lab_analysis_status)">
                        {{row.receipt_sample_date.lab_analysis_status}}
                      </small>
                    </div>
                    <div class="col-12">
                      <small><strong>Fecha:</strong></small>
                      <small>{{moment(row.receipt_sample_date.lab_analysis_dated_at).format('DD/MM/YY')}}</small>
                    </div>
                    <div class="col-12">
                      <strong>Responsable:</strong>
                      <span>{{row.receipt_sample_date.lab_analysis_responsible}}</span>
                    </div>
                  </div>
                </template>
                <div slot="expandedRowRender" slot-scope="row" style="margin: 0">
                  <p class="text-justify"><strong>Observaciones: </strong>{{ row.notes }}</p>
                </div>
              </a-table>
            </a-row>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">Análisis Realizados </span>
        <div class="card">
          <div class="card-body text-center">
            <a-row class="mb-4" type="flex" justify="end" v-if="!locked">
              <a-col :span="4">
                <b-button pill variant="primary" @click="Conclude">
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
              </a-col>
            </a-row>
            <a-row>
              <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                <a-table :scroll="{x:1100}" bordered
                  :dataSource="tablelabAnalysisT.data"
                  :columns="tablelabAnalysisT.columns"
                  :loading="tablelabAnalysisT.loading"
                  :row-key="record => record.id"
                  :pagination="tablelabAnalysisT.pagination"
                  :row-selection="{ width: 100, fixed:true, type: 'radio', columnTitle: 'Seleccionar', selectedRowKeys: selectedRowKeys, selectedRows: selectedRow, onChange: onSelectChange }"
                  @change="FetchLabAnalysisT">
                  <template slot="serviceDetail" slot-scope="row">
                    <div class="text-center">
                      <small>
                        <strong>Centro de Trabajo:</strong>
                      </small>
                      <small>
                        <strong>{{ row.work_centers.code }}</strong> -
                        {{ row.work_centers.name }}
                      </small>
                    </div>
                    <div class="text-center">
                      <small>
                        <strong>Cliente / Solicitante:</strong>
                      </small>
                      <small>{{ row.receipt_samples.lab_customer }}</small>
                    </div>
                    <hr class="bg-primary">
                    <div class="text-center">
                      <small>
                        <strong>No.Servicio:</strong>
                      </small>
                      <small>
                        <strong>{{ row.receipt_samples.invoice_code }}</strong>
                      </small>
                    </div>
                    <div class="text-center">
                      <small>
                        <strong>Corriente:</strong>
                      </small>
                      <small>
                        {{ row.receipt_samples.currents.name }}
                      </small>
                    </div>
                    <div class="text-center">
                      <small>
                        <strong>Material:</strong>
                      </small>
                      <small>
                        {{ row.receipt_samples.sample_material }}
                      </small>
                    </div>
                    <div class="text-center">
                      <small>
                        <strong>Tipo de Contenedor:</strong>
                      </small>
                      <small>{{ row.receipt_samples.container_types.name }}</small>
                    </div>
                  </template>
                  <template slot="sampleDetail" slot-scope="row">
                    <div class="text-center">
                      <small>
                        <strong>Tipo de Ingreso:</strong>
                      </small>
                      <small> {{ row.receipt_samples.sample_type }}</small>
                    </div>
                    <div class="text-center">
                      <small>
                        <strong>Cantidad de Muestras tomada: </strong>
                      </small>
                      <small>{{ row.receipt_samples.quantity }} </small>
                      <small> {{ row.receipt_samples.ums.name }}</small>
                    </div>
                    <hr class="bg-primary">
                    <div class="text-center">
                      <small>
                        <strong>Fecha de recepción:</strong>
                      </small>
                      <small v-if="row.receipt_samples && row.receipt_samples.receipt_sample_date && row.receipt_samples.request_dated_at">
                        {{ moment(row.receipt_samples.receipt_sample_date.request_dated_at).format('DD-MM-YYYY') }}
                      </small>
                    </div>
                  </template>
                  <span slot="status" slot-scope="row" :class="receipt_sample_date_status_class(row.status)" v-text="row.status"/>
                </a-table>
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span: 12}">
                <a-table bordered :scroll="{x:1300}" :columns="AnalysisElementsFromTransactionsTableObj.columns"
                    :data-source="AnalysisElementsFromTransactionsTableObj.data"
                    :pagination="AnalysisElementsFromTransactionsTableObj.pagination"
                    :loading="AnalysisElementsFromTransactionsTableObj.loading"
                    @change="GetElementsAssigned2Analysis"
                    :row-key="record => record.id">
                  <template slot="current_relations" slot-scope="row">{{row.map(current => current.name).join(',')}}</template>
                  <span slot="status" slot-scope="status" :class="labAnalysisSamples_status_class(status)" v-text="status"/>
                  <template slot="action" slot-scope="row">
                    <b-button v-if="showActionBtn" size="sm" pill variant="primary" @click="OpenResultModal(row)">
                      <b-icon icon="plus" v-b-tooltip.hover title="Detalles"/>
                    </b-button>
                    <b-button v-if="showActionBtn" pill variant="danger" @click="DeleteResult(row)">
                    <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/>
                    </div>
                    <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                      <b-icon icon="trash"/>
                    </div>
                    </b-button>
                  </template>
                </a-table>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    <!-- Creación de Muestra Manual -->
    <b-modal title="Registrar Nueva Muestra"
              ref="modalNewSample" size="xl" @hide="CloseSampleModal">
      <a-row type="flex" justify="end" v-if="!hideApply">
        <a-col :sm="{ span: 6 }" :md="{ span:4}">
          <a-checkbox v-model="apply">
              <strong class="text-primary">Aplica</strong>
          </a-checkbox>
        </a-col>
      </a-row>
      <a-form-model ref="formNewSample" layout="horizontal" v-show="apply"
          :model="sampleManualResourceObj.form"
          :rules="sampleManualFormRules">
        <!--DATEPICKER - Fecha de Recepción de Muestra-->
        <a-row>
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item  label="Fecha de Recepción de Muestra" ref="request_dated_at" prop="request_dated_at">
              <a-date-picker style="width:100%"
                v-model="sampleManualResourceObj.form.request_dated_at" placeholder="Fecha de Recepción de Muestra"
                              format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <!--DATEPICKER -Fecha de Recepción de Muestra -->
        <a-row>
          <!-- CUSTOM SELECT Centro de Trabajo -->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <customDropdownSelect refAndPop="work_center_id"
              v-model="sampleManualResourceObj.form.work_center_id"
            label="Centro de Trabajo" placeholder="Seleccionar Centro de Trabajo"
            resource="workCenters"/>
          </a-col>
          <!-- CUSTOM SELECT Centro de Trabajo -->
          <!--TEXT - Cliente Solicitante-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item label="Cliente Solicitante" ref="lab_customer"  prop="lab_customer" >
              <a-input v-model="sampleManualResourceObj.form.lab_customer" disabled placeholder="Cliente Solicitante" size="large"/>
            </a-form-model-item>
          </a-col>
          <!--TEXT -Cliente Solicitante -->
          <!--TEXT - No.Servicio-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item label="No.Servicio" ref="invoice_code"  prop="invoice_code" >
              <a-input v-model="sampleManualResourceObj.form.invoice_code" disabled
              placeholder="No.Servicio" size="large"/>
            </a-form-model-item>
          </a-col>
          <!--TEXT -No.Servicio -->
        </a-row>
        <a-row>
          <!--CUSTOM SELECT-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <customDropdownSelect v-model="sampleManualResourceObj.form.current_id"
            refAndPop="current_id" label="Corriente" :pageSize="25"
            placeholder="Seleccionar Corriente" resource="currents"/>
          </a-col>
          <!--CUSTOM SELECT-->
          <!--TEXT - Material-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item label="Material" ref="sample_material"  prop="sample_material" >
              <a-input v-model="sampleManualResourceObj.form.sample_material" placeholder="Material" size="large"/>
            </a-form-model-item>
          </a-col>
          <!--TEXT -Material -->
          <!--CUSTOM SELECT-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <customDropdownSelect v-model="sampleManualResourceObj.form.container_type_id"
            label="Tipo de Contenedor" :pageSize="25" placeholder="Seleccionar Tipo de Contenedor"
            resource="containerTypes"/>
          </a-col>
          <!--CUSTOM SELECT-->
        </a-row>
        <hr class="bg-primary">
        <a-row>
          <!--SELECT -Tipo de Ingresos -->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item label="Tipo de Ingreso" ref="sample_type" prop="sample_type" >
              <a-select v-model="sampleManualResourceObj.form.sample_type"
                        placeholder="Seleccionar Tipo de Ingresos"
                        show-search>
                <a-select-option  v-for="option in SampleTypesCombo" :key="option.value" :value="option.value" > {{option.text}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!--SELECT -Tipo de Ingresos -->
          <!--NUMBER INPUT - Cantidad de Muestra tomada-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <a-form-model-item label="Cantidad de Muestra tomada" ref="quantity"  prop="quantity" >
              <a-input-number style="width: 100%" v-model="sampleManualResourceObj.form.quantity" :min="0" :step="1" size="large"/>
            </a-form-model-item>
          </a-col>
          <!--NUMBER INPUT -Cantidad de Muestra tomada -->
          <!--UMS SELECT-->
          <a-col :sm="{ span: 12 }" :md="{ span:8}">
            <customDropdownSelect v-model="sampleManualResourceObj.form.u_m_s_id"
            label="UM" :pageSize="25" placeholder="Seleccionar Unidad de Medida"
            resource="ums"/>
          </a-col>
          <!--UMS SELECT-->
        </a-row>
        <a-row>
          <!--TEXT - Responsable-->
          <a-col :sm="{ span: 24 }" :md="{ span:12, offset:6 }">
            <a-form-model-item label="Responsable" ref="request_responsible"  prop="request_responsible" >
              <a-input v-model="sampleManualResourceObj.form.request_responsible" placeholder="Responsable" size="large"/>
            </a-form-model-item>
          </a-col>
          <!--TEXT -Responsable -->
        </a-row>
        <hr class="bg-primary">
        <a-row type="flex" justify="center">
          <a-col :sm="{ span: 24 }">
            <!--TEXT-AREA - Observaciones-->
            <a-form-model-item label="Observaciones" ref="notes"  prop="notes" >
              <a-textarea v-model="sampleManualResourceObj.form.notes" auto-size :rows="5" allowClear/>
            </a-form-model-item>
            <!--TEXT-AREA -Observaciones -->
          </a-col>
        </a-row>
        <hr class="bg-primary">
        <!-- Botones -->
        <a-row type="flex" justify="end" v-show="!spinner">
          <a-col>
            <b-button pill variant="primary" @click="PostSample">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/> Guardar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/>
            </div>
            </b-button>
          </a-col>
        </a-row>
        <!-- Botones -->
      </a-form-model>
      <a-form-model ref="formUpdateStep" layout="horizontal" v-show="!apply"
          :model="updateStepObj.form" :rules="updateStepRules">
          <a-row>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - Responsable de Laboratorio-->
              <a-form-model-item label="Responsable de Laboratorio" ref="compatibility_tests_lab_responsible" prop="compatibility_tests_lab_responsible">
                <a-input v-model="updateStepObj.form.compatibility_tests_lab_responsible"
                          placeholder="Responsable de Laboratorio" size="large"/>
              </a-form-model-item>
              <!--TEXT -Responsable de Laboratorio -->
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - Responsable de Operaciones-->
              <a-form-model-item label="Responsable de Operaciones" ref="compatibility_tests_operation_responsible" prop="compatibility_tests_operation_responsible">
                 <a-input v-model="updateStepObj.form.compatibility_tests_operation_responsible" placeholder="Responsable de Operaciones" size="large"/>
              </a-form-model-item>
              <!--TEXT -Responsable de Operaciones -->
            </a-col>
          </a-row>
          <a-row>
            <!--TEXT-AREA - Comentarios-->
            <a-form-model-item label="Comentarios" ref="compatibility_tests_comments" prop="compatibility_tests_comments">
              <a-textarea v-model="updateStepObj.form.compatibility_tests_comments" auto-size :rows="5"/>
            </a-form-model-item>
            <!--TEXT-AREA -Comentarios -->
          </a-row>
          <hr class="bg-primary">
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="UpdateStep">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
      </a-form-model>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseSampleModal"/>
      </template>
    </b-modal>
    <!-- Creación de Muestra Manual -->
    <!-- Steps Modal -->
    <b-modal title="Detalles" ref="modalSteps" size="xl" @hide="CloseStepsModal">
      <strong>Responsable de Laboratorio: </strong> {{steps.compatibility_tests_lab_responsible}} <br>
      <strong>Responsable de Operaciones: </strong> {{steps.compatibility_tests_operation_responsible}}<br>
      <strong>Comentarios: </strong> {{steps.compatibility_tests_comments}} <br>
      <strong>Estatus: </strong> {{steps.compatibility_tests_status}} <br>
      <strong>Fecha: </strong>
      <span v-if="steps.compatibility_tests_dated_at">{{moment(steps.empty_containers_dated_at).format('DD/MM/YYYY')}} </span>
      <span v-else> - </span>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseStepsModal"/>
      </template>
    </b-modal>
    <!-- Steps Modal -->
  </a-row>
</template>

<script>
import { SampleTypesCombo } from '@/constant/CombosApiV2'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
const SampleDefaultForm = {
  work_center_id: null,
  warehouse_manual_code: '',
  invoice_code: '',
  sample_type: 'Manual Interna',
  lab_customer: '',
  sample_material: '',
  service: {
    type: 'manual',
    id: 0,
  },
  quantity: null,
  u_m_s_id: null,
  container_type_id: null,
  current_id: null,
  request_dated_at: '',
  request_responsible: '',
  request_status: 'Concluido',
  notes: '',
}
const tableColumns = [
  {
    title: 'Código',
    dataIndex: 'code',
    key: '',
    class: 'text-center small',
  },
  {
    title: 'Detalle del Servicio',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'serviceDetail',
    },
  },
  {
    title: 'Datos de la muestra',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'sampleDetail',
    },
  },
  {
    title: 'Estatus de Procesos',
    dataIndex: '',
    key: '',
    class: 'text-center small',
    scopedSlots: {
      customRender: 'status',
    },
  },
]
const resource = {
  id: null,
  code: '',
  operation_shift_id: null,
  work_center_id: null,
  formulation_type_id: null,
  process_type_id: null,
  operation_technical_1: '',
  operation_technical_2: '',
  worked_hours: '',
  started_at: '',
  finished_at: '',
  status: '',
  deleted_at: null,
  created_at: '',
  updated_at: '',
  operation_shifts: {
    id: null,
    code: '',
    name: '',
    initial_time: '',
    final_time: '',
    work_center_id: null,
    technical_id: null,
    analyst_id: null,
    deleted_at: null,
    created_at: '',
    updated_at: '',
  },
  work_centers: {
    id: null,
    sap_code: '',
    code: '',
    name: '',
    deleted_at: null,
  },
  formulation_types: {
    id: null,
    name: '',
    details: '',
    created_at: '',
    updated_at: '',
  },
  process_types: {
    id: null,
    code: '',
    name: '',
    deleted_at: null,
  },
  step_formulations: {
    id: null,
    formulation_id: '',
    waste_dated_at: '',
    waste_responsible: null,
    waste_status: 'Pendiente',
    waste_block: '0',
    compatibility_tests_dated_at: '',
    compatibility_tests_lab_responsible: null,
    compatibility_tests_operation_responsible: null,
    compatibility_tests_status: 'Pendiente',
    compatibility_tests_block: '0',
    compatibility_tests_comments: null,
    empty_containers_dated_at: '',
    empty_containers_responsible: null,
    empty_containers_status: 'Pendiente',
    empty_containers_block: '0',
    crushings_dated_at: '',
    crushings_responsible: null,
    crushings_status: 'Pendiente',
    crushings_block: '0',
    solid_crushings_dated_at: '',
    solid_crushings_responsible: null,
    solid_crushings_status: 'Pendiente',
    solid_crushings_block: '0',
    tire_crushings_dated_at: '',
    tire_crushings_responsible: null,
    tire_crushings_status: 'Pendiente',
    tire_crushings_block: '0',
    supplies_crushings_dated_at: '',
    supplies_crushings_responsible: null,
    supplies_crushings_water_total_cost: null,
    supplies_crushings_status: 'Pendiente',
    supplies_crushings_block: '0',
    fiscal_inspections_dated_at: '',
    fiscal_inspections_responsible: null,
    fiscal_inspections_status: 'Pendiente',
    fiscal_inspections_block: '0',
    deleted_at: null,
    created_at: '',
    updated_at: '',
  },
}
const compatibilityTestsUpdateStep = {
  compatibility_tests_dated_at: '',
  compatibility_tests_lab_responsible: '',
  compatibility_tests_operation_responsible: '',
  compatibility_tests_status: 'No Aplica',
  compatibility_tests_block: true,
  compatibility_tests_comments: '',
}
export default {
  name: 'compatibilityTests',
  mixins: [apiV2Mixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      // combos
      SampleTypesCombo,
      // dom
      spinner: false,
      locked: false,
      // apply
      apply: false,
      hideApply: false,
      // form
      // default forms structures
      SampleDefault: JSON.stringify(SampleDefaultForm),
      resourceDefault: JSON.stringify(resource),
      compatibilityTestsStep: JSON.stringify(compatibilityTestsUpdateStep),
      // default forms structures
      sampleManualResourceObj: {
        resourceType: 'receiptSamples',
        form: {},
      },
      sampleManualFormRules: {
        work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        warehouse_manual_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        lab_customer: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        sample_material: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        container_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        request_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        request_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        current_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: tableColumns,
      },
      // Table of labAnalysisTransactions
      tablelabAnalysisT: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Código', width: 100, dataIndex: 'code', key: 'code', fixed: 'left', class: 'text-center small' },
          {
            title: 'Detalle del Servicio',
            key: 'serviceDetail',
            class: 'text-center small',
            scopedSlots: { customRender: 'serviceDetail' },
          },
          {
            title: 'Datos de la muestra',
            key: 'sampleDetail',
            class: 'text-center small',
            scopedSlots: { customRender: 'sampleDetail' },
          },
          {
            title: 'Estatus',
            key: 'status',
            fixed: 'right',
            class: 'text-center small',
            width: 100,
            scopedSlots: { customRender: 'status' },
          },
        ],
      },
      // Table of labAnalysisElements
      selectedRow: [],
      selectedRowKeys: [],
      // Table of labAnalysisElements from labAnalysisTransactions
      AnalysisElementsFromTransactionsTableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Elementos de Análisis',
            fixed: 'left',
            width: 100,
            dataIndex: 'lab_analysis_elements.name',
            key: 'name',
            class: 'text-center small',
          },
          {
            title: 'Corrientes',
            dataIndex: 'lab_analysis_elements.current_relations',
            key: 'current_relations',
            class: 'text-center small',
            scopedSlots: { customRender: 'current_relations' },
          },
          {
            title: 'Niv Inferior',
            dataIndex: 'lab_analysis_elements.down_limit',
            key: 'down_limit',
            class: 'text-center small',
          },
          {
            title: 'Lim. Superior',
            dataIndex: 'lab_analysis_elements.up_limit',
            key: 'up_limit',
            class: 'text-center small',
          },
          {
            title: 'Referencia',
            dataIndex: 'lab_analysis_elements.reference',
            key: 'reference',
            class: 'text-center small',
          },
          {
            title: 'UM',
            dataIndex: 'lab_analysis_elements.ums.name',
            key: '',
            class: 'text-center small',
          },
          {
            title: 'Resultado',
            dataIndex: 'result',
            key: '',
            class: 'text-center small',
          },
          {
            title: 'Estatus',
            dataIndex: 'status',
            key: '',
            class: 'text-center small',
            scopedSlots: { customRender: 'status' },
          },

        ],
      },
      // resource
      formulation: {},
      // update step
      updateStepObj: {
        resourceType: `stepFormulations/${this.formulationId}`,
        form: {},
      },
      updateStepRules: {
        // compatibility_tests_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        compatibility_tests_lab_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        compatibility_tests_operation_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        // compatibility_tests_status: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        // compatibility_tests_block: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        compatibility_tests_comments: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // steps
      steps: {
        compatibility_tests_dated_at: '',
        compatibility_tests_lab_responsible: null,
        compatibility_tests_operation_responsible: null,
        compatibility_tests_status: 'Pendiente',
        compatibility_tests_block: '0',
        compatibility_tests_comments: null,
      },
    }
  },
  methods: {
    // helpers
    receipt_sample_date_status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'status-tag-warning'
        case 'Cancelado':
          return 'status-tag-danger'
        case 'Concluido':
          return 'status-tag-primary'
        case 'No Aplica':
          return 'status-tag-secondary'
        default:
          return 'status-tag-secondary'
      }
    },
    receipt_sample_date_status_class2(status) {
      switch (status) {
        case 'Pendiente':
          return 'font-weight-bold text-warning'
        case 'Cancelado':
          return 'font-weight-bold text-danger'
        case 'Concluido':
          return 'font-weight-bold text-primary'
        case 'No Aplica':
          return 'font-weight-bold text-secondary'
        default:
          return 'font-weight-bold text-secondary'
      }
    },
    labAnalysisSamples_status_class(status) {
      switch (status) {
        case 'Pendiente':
          return 'status-tag-warning'
        case 'Rechazada':
          return 'status-tag-danger'
        case 'Aceptada':
        case 'Aceptada con Desviación':
          return 'status-tag-primary'
        case 'No Aplica':
          return 'status-tag-secondary'
        default:
          return 'status-tag-secondary'
      }
    },
    // fetch of formulation
    async FetchResource() {
      const url = `formulations/${this.formulationId}`
      this.fetching = true
      const order = await this.AxiosGetv2(url, {})
      if (order) {
        this.formulation.id = order.data.id
        this.formulation.code = order.data.code
        this.formulation.operation_shift_id = order.data.operation_shift_id
        this.formulation.work_center_id = order.data.work_center_id
        this.formulation.formulation_type_id = order.data.formulation_type_id
        this.formulation.process_type_id = order.data.process_type_id
        this.formulation.operation_technical_1 = order.data.operation_technical_1
        this.formulation.operation_technical_2 = order.data.operation_technical_2
        this.formulation.worked_hours = order.data.worked_hours
        this.formulation.started_at = order.data.started_at
        this.formulation.finished_at = order.data.finished_at
        this.formulation.status = order.data.status
        this.formulation.deleted_at = order.data.deleted_at
        this.formulation.created_at = order.data.created_at
        this.formulation.updated_at = order.data.updated_at
        this.formulation.operation_shifts = order.data.operation_shifts
        this.formulation.work_centers = order.data.work_centers
        this.formulation.formulation_types = order.data.formulation
        this.formulation.process_types = order.data.process_types
        this.formulation.step_formulations = order.data.step_formulations
        const block = order.data.step_formulations.compatibility_tests_block
        switch (block) {
          case '0':
          case 0:
          case '':
          case false:
            this.locked = false
            break
          case 1:
          case '1':
          case true:
            this.locked = true
            break
          default:
            this.locked = false
            break
        }
      }
      const stepsUrl = `stepFormulations/${this.formulationId}`
      const steps = await this.AxiosGetv2(stepsUrl, {})
      if (steps && steps.data && Object.keys(steps.data).length > 0) {
        this.steps.compatibility_tests_dated_at = steps.data.compatibility_tests_dated_at
        this.steps.compatibility_tests_lab_responsible = steps.data.compatibility_tests_lab_responsible
        this.steps.compatibility_tests_operation_responsible = steps.data.compatibility_tests_operation_responsible
        this.steps.compatibility_tests_status = steps.data.compatibility_tests_status
        this.steps.compatibility_tests_block = steps.data.compatibility_tests_block
        this.steps.compatibility_tests_comments = steps.data.compatibility_tests_comments
      }
      this.fetching = false
    },
    // fetch of formulation
    // form
    OpenSampleModal() {
      this.spinner = false
      this.hideApply = false
      this.sampleManualResourceObj.form = JSON.parse(this.SampleDefault)
      this.updateStepObj.form = JSON.parse(this.compatibilityTestsStep)
      this.sampleManualResourceObj.form.work_center_id = this.formulation.work_center_id
      this.sampleManualResourceObj.form.invoice_code = this.formulation.code
      this.sampleManualResourceObj.form.lab_customer = 'Operaciones'
      this.$refs.modalNewSample.show()
    },
    CloseSampleModal() {
      this.spinner = false
      this.$refs.modalNewSample.hide()
      this.sampleManualResourceObj.form = JSON.parse(this.SampleDefault)
      this.updateStepObj.form = JSON.parse(this.compatibilityTestsStep)
    },
    async PostSample() {
      this.$refs.formNewSample.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.sampleManualResourceObj.form.warehouse_manual_code = this.sampleManualResourceObj.form.invoice_code
          await this.PostResourcev2(this.sampleManualResourceObj, async () => {
            await this.CloseSampleModal()
            this.spinner = false
            await this.FetchTable()
          })
        } else return false
      })
    },
    // form
    // table
    async FetchTable(pagE) {
      const params = {
        orderField: 'code',
        orderType: 'desc',
        statusType: 'request_status',
        statusValue: 'Concluido',
        workCenterFilter: this.formulation.work_center_id,
        search: this.formulation.code,
      }
      await this.GetTableResourcev2(pagE, 'receiptSamples', this.tableObj, params)
    },
    // table
    // Table of labAnalysisTransactions
    async FetchLabAnalysisT(pagE) {
      const params = {
        orderField: 'code',
        orderType: 'asc',
        workCenterFilter: this.formulation.work_center_id,
        search: this.formulation.code,
      }

      await this.GetTableResourcev2(pagE, 'labAnalysisTransactions', this.tablelabAnalysisT, params)
    },
    async onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRow = selectedRows
      this.selectedRowKeys = _selectedRowKeys
      if (selectedRows.length > 0 && selectedRows[0].status !== 'Pendiente') {
        this.ownElements = true
        await this.GetElementsAssigned2Analysis()
      }
    },
    async GetElementsAssigned2Analysis(pagE) {
      this.loading = true
      if (this.selectedRow.length > 0 && this.selectedRow[0].id) {
        const params = {
          labAnalysisTransactionId: this.selectedRow[0].id,
          orderField: 'id',
          orderType: 'asc',
        }
        if (this.AnalysisElementsFromTransactionsTableObj.search) params.search = this.AnalysisElementsFromTransactionsTableObj.search
        await this.GetTableResourcev2(pagE, 'labAnalysisSamples', this.AnalysisElementsFromTransactionsTableObj, params)
      }
      this.loading = false
    },
    Conclude() {
      this.$refs.modalNewSample.show()
      this.apply = false
      this.hideApply = true
      this.updateStepObj.form = JSON.parse(this.compatibilityTestsStep)
      this.updateStepObj.form.compatibility_tests_status = 'Concluido'
    },
    async UpdateStep() {
      const me = this
      this.$refs.formUpdateStep.validate(async(valid) => {
        if (valid) {
          Modal.confirm({
            title: '¿Esta seguro de concluir las pruebas de compatibilidad?',
            content: 'Esta operación es irreversible',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 3000,
            async onOk () {
              me.updateStepObj.form.compatibility_tests_dated_at = moment().format('YYYY-MM-DD')
              me.spinner = true
              await me.PutResourcev2(me.updateStepObj, async () => {
                await me.CloseSampleModal()
                me.spinner = false
                await me.FetchResource()
              })
            },
          })
        } else return false
      })
    },
    OpenStepsModal() {
      this.$refs.modalSteps.show()
    },
    CloseStepsModal() {
      this.$refs.modalSteps.show()
    },
  },
  async mounted() {
    await this.FetchResource()
    await this.FetchTable()
    await this.FetchLabAnalysisT()
  },
}
</script>

<style>

</style>
