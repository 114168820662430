<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="row justify-content-start" slot="customFilters">
        <div class="col-sm-12 col-md-6 col-lg-6" style="text-align:center">
          <a-form-model-item label="Costo Total de Insumos"
                                        class="mx-1"
                                        ref="totalCost"
                                        prop="totalCost">
            <a-input-number v-model="totalCost" :disabled="flag1"
                              style="width: 100%"
                              :min="0" :max="100000"
                              :step="1"/>
          </a-form-model-item>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6" >
          <a-form-model-item label="Costo T/agua"
                                        class="mx-1"
                                        ref="supplies_crushings_water_total_cost"
                                        prop="supplies_crushings_water_total_cost">
            <a-input-number v-model="updateSteps.form.supplies_crushings_water_total_cost"
                              style="width: 100%"
                              :min="0" :max="100000"
                              :step="1" :disabled="disableControls"
              />
          </a-form-model-item>
        </div>
      </div>
      <div class="row justify-content-end" style="margin-right:10px">
        <b-button-group>
          <b-button :disabled="disableControls" variant="primary" @click="SaveAll" pill style="margin-right:10px">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/> Guardar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/>
            </div>
          </b-button>
          <b-button :disabled="disableControls" variant="warning" @click="Register" pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Registrar Insumo">
                <b-icon icon="plus-circle"/> Registrar Insumo
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Registrar Insumo">
                <b-icon icon="plus-circle"/>
            </div>
          </b-button>
        </b-button-group>
      </div>
      <div class="row">
        <div class="col-12" style="margin-top:20px">
          <a-table :columns="tableObj.columns"
             :data-source="tableObj.data"
             :pagination="tableObj.pagination"
             :loading="tableObj.loading"
             @change="FetchTable"
             :row-key="record => record.id"
          >
            <template slot="column1" slot-scope="record">
              <small>
                <strong>
                  {{ record.name }}
                </strong>
              </small>
            </template>
            <template slot="column2" slot-scope="record">
              <small>
                <strong>
                  {{ record.quantity }} - {{ record.ums.code }}
                </strong>
              </small>
            </template>
            <template slot="column3" slot-scope="record">
              <small>
                <strong>
                  {{ record.unit_cost }}
                </strong>
              </small>
            </template>
            <template slot="column4" slot-scope="record">
              <small>
                <strong>
                  {{ record.totalCostP }}
                </strong>
              </small>
            </template>
          </a-table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row justify-content-end align-self-center">
            <b-modal size="lg"
            :title="'Agregar Insumo'"
            v-model="showModal" ref="modal-Form">
              <my-spinner :load="spinnerLoad"/>
              <b-container fluid v-show="!spinnerLoad">
                <a-form-model :rules="formRules" :model="resourceObj.form" ref="rule-Form"
                    layout="vertical" :wrapper-col="{ span: 24 }">
                  <a-form-model-item label="Nombre de Insumo:"
                                        class="mx-1"
                                        ref="name"
                                        prop="name"
                  >
                    <a-input v-model="resourceObj.form.name"
                                      style="width: 100%"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Cantidad de Insumo"
                                        class="mx-1"
                                        ref="quantity"
                                        prop="quantity"
                  >
                    <a-input-number v-model="resourceObj.form.quantity"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <a-form-model-item label="Tipo de Unidad" ref="u_m_s_id"  prop="u_m_s_id" >
                    <a-select v-model="resourceObj.form.u_m_s_id" allowClear
                            placeholder="Seleccionar tipo de Unidad" show-search>
                      <a-select-option  v-for="option in ums" :key="option.id" :value="option.id" >
                        {{option.code}}-{{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="Costo Unitario"
                                        class="mx-1"
                                        ref="unit_cost"
                                        prop="unit_cost"
                  >
                    <a-input-number v-model="resourceObj.form.unit_cost"
                                      style="width: 100%"
                                      :min="0"
                                      :max="100000"
                                      :step="1"
                      />
                  </a-form-model-item>
                  <div class="row justify-content-end">
                    <b-button-group>
                      <b-button  variant="primary" @click="SendInput" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/> Guardar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/>
                        </div>
                      </b-button>
                    </b-button-group>
                  </div>
                </a-form-model>
              </b-container>
              <template v-slot:modal-footer>
                <simple-modal-footer @ClickEvent="closeSampleModal"/>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
export default {
  name: 'inputCrushing',
  mixins: [fractalMixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
    steps: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      disableControls: false,
      flag1: true,
      totalCost: 0,
      showModal: false,
      spinnerLoad: false,
      ums: [],
      resourceObj1: {
        resourceType: 'stepFormulations',
        form: {
          id: '',
          formulation_id: undefined,
          supplies_crushings_water_total_cost: '',
        },
      },
      resourceObj: {
        resourceType: 'suppliesCrushings',
        form: {
          id: '',
          formulation_id: parseInt(this.formulationId),
          name: '',
          quantity: undefined,
          u_m_s_id: undefined,
          unit_cost: undefined,
        },
      },
      updateSteps: {
        resourceType: 'stepFormulations',
        form: {
          id: parseInt(this.formulationId),
          formulation_id: parseInt(this.formulationId),
          supplies_crushings_dated_at: '',
          supplies_crushings_responsible: '',
          supplies_crushings_water_total_cost: '',
          supplies_crushings_status: '',
          supplies_crushings_block: false,
        },
      },
      formRules: {
        name: [{ required: true, message: 'El nombre del insumo es obligatorio.', trigger: 'blur' }],
        quantity: [{ required: true, message: 'La cantidad de insumo es obligatorio.', trigger: 'blur' }],
        u_m_s_id: [{ required: true, message: 'La unidad es obligatorio', trigger: 'blur' }],
        unit_cost: [{ required: true, message: 'El costo unitario es obligatorio', trigger: 'blur' }],
      },
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Insumo',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column1' },
            width: '15%',
          },
          {
            title: 'Cantidad',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column2' },
          },
          {
            title: 'Costo Unitario',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column3' },
          },
          {
            title: 'Costo Total',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'column4' },
          },
        ],
      },
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      let array = []
      const params = {
        formulationId: this.formulationId,
      }
      this.totalCost = 0
      await this.GetTableResourcev2(pagEvent, 'suppliesCrushings', this.tableObj, params).then(
        () => {
          this.tableObj.data.forEach(element => {
            element.totalCostP = parseFloat(element.quantity) * parseFloat(element.unit_cost)
            this.totalCost += element.totalCostP
          })
          array = this.tableObj.data
          this.tableObj.data = []
          this.tableObj.data = array
          console.log('Total cost', this.totalCost)
        },
      )
    },
    SaveAll() {
      this.closeCrushingMessage('Esta acción concluirá el proceso de adición de insumos. ¿Desea Continuar?')
    },
    async Register() {
      this.spinnerLoad = true
      this.showModal = true
      await this.fetchCombos().then(
        () => {
          this.spinnerLoad = false
          this.resourceObj.form = {
            formulation_id: parseInt(this.formulationId),
            name: '',
            quantity: undefined,
            u_m_s_id: undefined,
            unit_cost: undefined,
          }
        },
      )
    },
    closeSampleModal() {
      this.showModal = false
    },
    async SendInput() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          console.log('Tabla: ', this.resourceObj.form)
          this.spinnerLoad = true
          await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.closeSampleModal()
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/ums',
          params: params1,
        },
      ]
      const [ums] = await this.GetConcurrencyResources(arr)
      this.ums = ums
    },
    closeCrushingMessage(message) {
      Modal.confirm({
        title: '¡Concluyendo proceso de Trituración!',
        content: message,
        okText: 'Ok',
        cancelText: 'Cancelar',
        centered: true,
        zIndex: 3000,
        onOk: () => { this.updateStep() },
      })
    },
    checkSteps() {
      if (this.steps.data.length > 0) {
        if (this.steps.data[0].supplies_crushings_status === 'Concluido') {
          this.disableControls = true
        } else {
          this.disableControls = false
        }
        this.updateSteps.form.id = parseInt(this.formulationId)
        this.updateSteps.form.formulation_id = this.formulationId
        this.updateSteps.form.supplies_crushings_dated_at = ''
        this.updateSteps.form.supplies_crushings_responsible = ''
        this.updateSteps.form.supplies_crushings_status = this.steps.data[0].supplies_crushings_status
        this.updateSteps.form.supplies_crushings_block = this.steps.data[0].crushings_block
        this.updateSteps.form.supplies_crushings_water_total_cost = this.steps.data[0].supplies_crushings_water_total_cost
      }
    },
    async updateStep() {
      this.updateSteps.form.supplies_crushings_status = 'Concluido'
      this.updateSteps.form.supplies_crushings_block = true
      this.updateSteps.form.supplies_crushings_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
      await this.PutResourcev2(this.updateSteps, this.SuccessCallback, () => { this.spinnerLoad = false })
      if (this.updateSteps.form.supplies_crushings_status === 'Concluido') {
        this.disableControls = true
      } else {
        this.disableControls = false
      }
      this.$emit('onChangeValue')
    },
  },
  async mounted() {
    await this.FetchTable()
    this.checkSteps()
  },
}
</script>
