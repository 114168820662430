<template>
  <b-container>
    <my-spinner :load="fetching"/>
    <!--FORMULARIO-->
    <a-form-model :model="resourceObj.form" :rules="resourceRules"
                  ref="ruleForm" layout="vertical" labelAlign="left"
                  :wrapper-col="{ span: 24}">
      <a-row align="middle">
        <!-- SELECT Planta -->
        <customDropdownWorkCenterSelect v-model="resourceObj.form.work_center_id" refAndPop="work_center_id"/>
        <!-- SELECT Planta -->
      </a-row>
      <h5 class="text-primary">Datos Generales de Planeación de Producción</h5>
      <hr class="bg-primary">
      <a-row align="middle">
        <a-col :sm="{ span: 24 }" :md="{ span:12}">
          <!--TEXT - No. Orden-->
          <a-form-model-item label="No. Orden" ref="code"  prop="code" >
            <a-input v-model="resourceObj.form.code" disabled
                    placeholder="No. Orden" size="large"/>
          </a-form-model-item>
          <!--TEXT -No. Orden -->
        </a-col>
        <a-col :sm="{ span: 24 }" :md="{ span:12}">
          <!--SELECT operationShifts-->
          <customDropdownSelect v-model="resourceObj.form.operation_shift_id" refAndPop="operation_shift_id"
                                :filters="filter4Shifts" label="Turno" resource="operationShifts"/>
        </a-col>
      </a-row>
      <a-row align="middle">
        <a-col :sm="{ span: 24 }" :md="{ span:12}">
          <!--TEXT - Técnico de Operaciones-->
          <a-form-model-item label="Técnico de Operación" ref="operation_technical_1"  prop="operation_technical_1" >
             <a-input v-model="resourceObj.form.operation_technical_1" placeholder="Técnico de Operación" size="large"/>
          </a-form-model-item>
          <!--TEXT -Técnico de Operaciones -->
        </a-col>
        <a-col :sm="{ span: 24 }" :md="{ span:12}">
          <!--TEXT - Técnico de Operaciones-->
          <a-form-model-item label="Técnico de Operación 2" ref="operation_technical_2"  prop="operation_technical_2" >
             <a-input v-model="resourceObj.form.operation_technical_2" placeholder="Técnico de Operación 2" size="large"/>
          </a-form-model-item>
          <!--TEXT -Técnico de Operaciones -->
        </a-col>
      </a-row>
      <h5 class="text-primary">Planeación de Producción</h5>
      <hr class="bg-primary">
      <a-row align="middle">
        <a-col :sm="{ span: 10 }" :md="{ span: 9 }">
          <!--DATEPICKER - Fecha y hora de inicio de plan-->
          <a-form-model-item  label="Fecha y hora de inicio de plan"
                              ref="started_at" prop="started_at">
            <a-date-picker  v-model="resourceObj.form.started_at"
                            placeholder="Fecha y hora de inicio de plan" style="width:100%"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY-MM-DD HH:mm"/>
          </a-form-model-item>
          <!--DATEPICKER -Fecha y hora de inicio de plan -->
        </a-col>
        <a-col :sm="{ span: 10 }" :md="{ span: 9 }">
          <!--DATEPICKER - Fecha y hora de final de plan-->
          <a-form-model-item  label="Fecha y hora de final de plan"
                              ref="finished_at" prop="finished_at">
            <a-date-picker  v-model="resourceObj.form.finished_at"
                            placeholder="Fecha y hora de final de plan" style="width:100%"
                            format="DD/MM/YYYY HH:mm" size="large" valueFormat="YYYY-MM-DD HH:mm"/>
          </a-form-model-item>
          <!--DATEPICKER -Fecha y hora de final de plan -->
        </a-col>
        <a-col :sm="{ span: 4 }" :md="{ span: 6 }">
          <!--NUMBER INPUT - Horas Trabajadas-->
          <a-form-model-item label="Horas Trabajadas" ref="worked_hours"  prop="worked_hours" >
             <a-input-number v-model="resourceObj.form.worked_hours" style="width:100%"
                            :min="0" :step="1" size="large"/>
          </a-form-model-item>
          <!--NUMBER INPUT -Horas Trabajadas -->
        </a-col>
      </a-row>
      <h5 class="text-primary">Información de Planeación de la Orden de Producción</h5>
      <hr class="bg-primary">
      <a-row align="middle">
        <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
          <!-- SELECT Tipo de Proceso -->
          <customDropdownSelect label="Tipo de Proceso" v-model="resourceObj.form.process_type_id"
                                refAndPop="process_type_id" resource="processTypes"/>
          <!-- SELECT Tipo de Proceso -->
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
          <!-- SELECT Tipo de Formulación -->
          <customDropdownSelect label="Tipo de Formulación" v-model="resourceObj.form.formulation_type_id"
                                refAndPop="formulation_type_id" resource="formulationTypes"/>
          <!-- SELECT Tipo de Formulación -->
        </a-col>
        <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
          <!--SELECT -Estatus -->
          <a-form-model-item label="Estatus" ref="status" prop="status" >
            <a-select v-model="resourceObj.form.status"
                      placeholder="Estatus" :disabled="disabled_status">
              <a-select-option  v-for="option in productionOrderCombo"
                                :key="option.value" :value="option.value">
                {{option.text}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Estatus -->
        </a-col>
      </a-row>
      <!-- Botones -->
      <my-spinner :load="spinner"/>
      <a-row type="flex" justify="end" v-show="!spinner">
        <a-col>
          <b-button pill variant="primary" @click="Post">
          <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
            <b-icon icon="archive"/> Guardar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Guardar">
            <b-icon icon="archive"/>
          </div>
          </b-button>
        </a-col>
      </a-row>
      <!-- Botones -->
    </a-form-model>
    <!--FORMULARIO-->
  </b-container>
</template>

<script>
import { productionOrderCombo } from '@/constant/CombosApiV2'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const baseFormDefault = {
  code: 'NA',
  operation_shift_id: null,
  operation_technical_1: '',
  operation_technical_2: '',
  started_at: '',
  finished_at: '',
  worked_hours: 0,
  process_type_id: null,
  formulation_type_id: null,
  status: 'Pendiente',
  work_center_id: null,
}
export default {
  name: 'productionOrderForm',
  mixins: [apiV2Mixin],
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      // constants
      defaulForm: JSON.stringify(baseFormDefault),
      productionOrderCombo,
      // dom variables
      fetching: false,
      spinner: false,
      // form
      resourceObj: {
        resourceType: 'formulations',
        form: {},
      },
      resourceRules: {
        work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        operation_shift_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        operation_technical_1: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        operation_technical_2: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        started_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        finished_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        worked_hours: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        process_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        formulation_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
        // status: [{ required: true, message: 'Campo obligatorio.', trigger: 'blur' }],
      },
    }
  },
  computed: {
    disabled_status() {
      return ((!this.id) || (this.resourceObj.form.status === 'Concluido' || this.resourceObj.form.status === 'Cancelado'))
    },
    disabled_code () {
      return !(this.id === undefined || this.id === null || this.id === '')
    },
    filter4Shifts() {
      return {
        workCenterId: this.resourceObj.form.work_center_id,
      }
    },
  },
  methods: {
    async FetchResource() {
      this.fetching = true
      this.resourceObj.form = JSON.parse(this.defaulForm)
      const order = await this.AxiosGetv2(`formulations/${this.id}`, {})
      if (order) {
        this.resourceObj.form.code = order.data.code
        this.resourceObj.form.operation_shift_id = parseInt(order.data.operation_shift_id)
        this.resourceObj.form.operation_technical_1 = order.data.operation_technical_1
        this.resourceObj.form.operation_technical_2 = order.data.operation_technical_2
        this.resourceObj.form.started_at = order.data.started_at
        this.resourceObj.form.finished_at = order.data.finished_at
        this.resourceObj.form.worked_hours = order.data.worked_hours
        this.resourceObj.form.process_type_id = parseInt(order.data.process_type_id)
        this.resourceObj.form.formulation_type_id = parseInt(order.data.formulation_type_id)
        this.resourceObj.form.status = order.data.status
        this.resourceObj.form.work_center_id = parseInt(order.data.work_center_id)
      }
      this.fetching = false
    },
    async SetUpForm() {
      if (this.id) {
        await this.FetchResource()
      } else this.resourceObj.form = JSON.parse(this.defaulForm)
    },
    async Post() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          if (this.id) {
            this.resourceObj.resourceType = `formulations/${this.id}`
            this.PutResourcev2(this.resourceObj, async () => {
              this.spinner = false
            }, () => {})
          } else {
            this.PostResourcev2(this.resourceObj, async () => {
              this.spinner = false
              this.resourceObj.resourceType = 'formulations'
              this.resourceObj.form = JSON.parse(this.defaulForm)
              this.$router.push({ name: 'production_orders' })
            }, () => {})
          }
        } else return false
      })
    },
  },
  async mounted() {
    await this.SetUpForm()
  },
}
</script>
