<template>
  <div>
    <my-spinner :load="fetching"/>
    <a-row type="flex" justify="end" class="mb-4">
      <a-col :span="4" v-if="!locked">
        <b-button pill variant="primary" @click="OpenModal()">
         <div class="d-none d-md-block" v-b-tooltip.hover title="Registrar Contenedor">
           <b-icon icon="plus"/> Registrar Contenedor
         </div>
         <div class="d-md-none" v-b-tooltip.hover title="Registrar Contenedor">
           <b-icon icon="plus"/>
         </div>
        </b-button>
      </a-col>
      <a-col :span="4" v-if="!locked">
        <b-button pill variant="info" @click="StoreEmptyContainers">
         <div class="d-none d-md-block" v-b-tooltip.hover title="Almacenar contenedores">
           <b-icon icon="archive-fill"/> Almacenar contenedores
         </div>
         <div class="d-md-none" v-b-tooltip.hover title="Almacenar contenedores">
           <b-icon icon="archive-fill"/>
         </div>
        </b-button>
      </a-col>
      <a-col :sm="{ span: 6 }" :md="{ span:4}">
        <b-button pill variant="info" @click="OpenStepsModal()">
          <div class="d-none d-md-block" v-b-tooltip.hover title="Información">
            <b-icon icon="info-circle"/> Información
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Información">
            <b-icon icon="info-circle"/>
          </div>
        </b-button>
      </a-col>
    </a-row>
    <b-modal title="Registrar Contenedor Vacío"
              ref="modalContainers" size="xl" @hide="CloseModal">
      <a-form-model ref="formContainers" layout="horizontal"
          :model="resObj.form" :rules="resRules">
          <!-- SELECT Tipo de Contenedor -->
          <customDropdownSelect label="Tipo de Contenedor" v-model="resObj.form.container_type_id" refAndPop="container_type_id" resource="containerTypes"/>
          <!-- SELECT Tipo de Contenedor -->
        <!--NUMBER INPUT - Cantidad-->
        <a-form-model-item label="Cantidad" ref="quantity"  prop="quantity">
           <a-input-number v-model="resObj.form.quantity" :min="0" :step="1"
                          style="width:25%" size="large"/>
        </a-form-model-item>
        <!--NUMBER INPUT -Cantidad -->
        <!-- Botones -->
        <a-row type="flex" justify="end" v-show="!spinner">
          <a-col>
            <b-button pill variant="primary" @click="PostContainer">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/> Guardar
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Guardar">
              <b-icon icon="archive"/>
            </div>
            </b-button>
          </a-col>
        </a-row>
        <!-- Botones -->
      </a-form-model>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <a-row>
      <!--TABLA-->
      <a-table bordered :scroll="{x:1300}" :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          @change="FetchContainers"
          :row-key="record => record.id">
          <template slot="actions" slot-scope="row">
            <b-button pill variant="secondary" @click="OpenModal(row)"  v-if="!locked">
             <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
               <b-icon icon="pencil-square"/> Editar
             </div>
             <div class="d-md-none" v-b-tooltip.hover title="Editar">
               <b-icon icon="pencil-square"/>
             </div>
            </b-button>
          </template>
      </a-table>
      <!--TABLA-->
    </a-row>
    <a-row type="flex" justify="center" class="mt-4">
        <b-button pill variant="primary" @click="OpenStepModal()"  v-if="!locked">
         <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
           <b-icon icon="archive"/> Guardar
         </div>
         <div class="d-md-none" v-b-tooltip.hover title="Guardar">
           <b-icon icon="archive"/>
         </div>
        </b-button>
        <b-modal  ref="modalStep" size="xl" @hide="CloseStepModal">
          <a-form-model ref="formStep" layout="horizontal"
              :model="updateStepObj.form" :rules="updateStepRules">
            <!--TEXT - Responsable-->
            <a-form-model-item label="Responsable" ref="empty_containers_responsible"  prop="empty_containers_responsible">
                <a-input v-model="updateStepObj.form.empty_containers_responsible" placeholder="Responsable" size="large"/>
            </a-form-model-item>
            <!--TEXT -Responsable -->
            <!-- Botones -->
            <a-row type="flex" justify="end" v-show="!spinner">
              <a-col>
                <b-button pill variant="primary" @click="UpdateStep">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
                </b-button>
              </a-col>
            </a-row>
            <!-- Botones -->
          </a-form-model>
          <template v-slot:modal-footer>
            <simple-modal-footer @ClickEvent="CloseStepModal"/>
          </template>
        </b-modal>
    </a-row>
    <!-- Steps Modal -->
    <b-modal title="Detalles" ref="modalSteps" size="xl" @hide="CloseStepsModal">
      <strong>Responsable: </strong> {{steps.empty_containers_responsible}} <br>
      <strong>Estatus: </strong> {{steps.empty_containers_status}} <br>
      <strong>Fecha: </strong>
      <span v-if="steps.empty_containers_dated_at">{{moment(steps.empty_containers_dated_at).format('DD/MM/YYYY')}} </span>
      <span v-else> - </span>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseStepsModal"/>
      </template>
    </b-modal>
    <!-- Steps Modal -->
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
import { Modal } from 'ant-design-vue'
import moment from 'moment'
const containerForm = {
  formulation_id: null,
  container_type_id: null,
  quantity: 1,
}
const UpdateStep = {
  empty_containers_dated_at: '',
  empty_containers_responsible: '',
  empty_containers_status: 'Concluido',
  empty_containers_block: true,
}
export default {
  name: 'emptyContainers',
  mixins: [apiV2Mixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      // dom
      spinner: false,
      fetching: false,
      locked: false,
      // default forms structures
      defaultForm: JSON.stringify(containerForm),
      defaultStepForm: JSON.stringify(UpdateStep),
      // container form
      resObj: {
        resourceType: 'emptyContainers',
        form: {},
      },
      resRules: {
        container_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Contenedor',
            dataIndex: 'container_types.name',
            key: 'container_types',
            class: 'text-center small',
          },
          {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            class: 'text-center small',
          },
          {
            title: 'Acciones',
            dataIndex: '',
            key: 'actions',
            class: 'text-center small',
            scopedSlots: {
              customRender: 'actions',
            },
          },
        ],
      },
      // update step
      updateStepObj: {
        resourceType: `stepFormulations/${this.formulationId}`,
        form: {},
      },
      updateStepRules: {
        empty_containers_responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
      },
      // resource
      formulation: {},
      // steps
      steps: {
        empty_containers_dated_at: '',
        empty_containers_responsible: null,
        empty_containers_status: 'Pendiente',
        empty_containers_block: '0',
      },
    }
  },
  methods: {
    // fetch of formulation
    async FetchResource() {
      const url = `formulations/${this.formulationId}`
      this.fetching = true
      const order = await this.AxiosGetv2(url, {})
      if (order) {
        this.formulation.id = order.data.id
        this.formulation.code = order.data.code
        this.formulation.operation_shift_id = order.data.operation_shift_id
        this.formulation.work_center_id = order.data.work_center_id
        this.formulation.formulation_type_id = order.data.formulation_type_id
        this.formulation.process_type_id = order.data.process_type_id
        this.formulation.operation_technical_1 = order.data.operation_technical_1
        this.formulation.operation_technical_2 = order.data.operation_technical_2
        this.formulation.worked_hours = order.data.worked_hours
        this.formulation.started_at = order.data.started_at
        this.formulation.finished_at = order.data.finished_at
        this.formulation.status = order.data.status
        this.formulation.deleted_at = order.data.deleted_at
        this.formulation.created_at = order.data.created_at
        this.formulation.updated_at = order.data.updated_at
        this.formulation.operation_shifts = order.data.operation_shifts
        this.formulation.work_centers = order.data.work_centers
        this.formulation.formulation_types = order.data.formulation
        this.formulation.process_types = order.data.process_types
        this.formulation.step_formulations = order.data.step_formulations
        const block = order.data.step_formulations.empty_containers_block
        switch (block) {
          case '0':
          case 0:
          case '':
          case false:
            this.locked = false
            break
          case 1:
          case '1':
          case true:
            this.locked = true
            break
          default:
            this.locked = false
            break
        }
      }
      const stepsUrl = `stepFormulations/${this.formulationId}`
      const steps = await this.AxiosGetv2(stepsUrl, {})
      if (steps && steps.data && Object.keys(steps.data).length > 0) {
        this.steps.empty_containers_dated_at = steps.data.empty_containers_dated_at
        this.steps.empty_containers_responsible = steps.data.empty_containers_responsible
        this.steps.empty_containers_status = steps.data.empty_containers_status
        this.steps.empty_containers_block = steps.data.empty_containers_block
      }
      this.fetching = false
    },
    OpenModal(data = {}) {
      this.spinner = false
      this.resObj.form = JSON.parse(this.defaultForm)
      this.resObj.form.formulation_id = this.formulationId
      if (Object.keys(data).length > 0) {
        this.resObj.form.container_type_id = parseInt(data.container_type_id)
        this.resObj.form.quantity = data.quantity
        this.resObj.resourceType = `emptyContainers/${data.id}`
      }
      this.$refs.modalContainers.show()
    },
    CloseModal() {
      this.spinner = false
      this.resObj.form = JSON.parse(this.defaultForm)
      this.resObj.resourceType = 'emptyContainers'
      this.$refs.modalContainers.hide()
    },
    async PostContainer() {
      this.$refs.formContainers.validate(async(valid) => {
        if (valid) {
          if (this.resObj.resourceType !== 'emptyContainers') {
            // put
            await this.PutResourcev2(this.resObj, async () => {
              await this.FetchContainers()
              this.CloseModal()
            })
          } else {
            // post
            await this.PostResourcev2(this.resObj, async () => {
              await this.FetchContainers()
              this.CloseModal()
            })
          }
        } else return false
      })
    },
    async FetchContainers(pagE) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagE, 'emptyContainers', this.tableObj, params)
    },
    OpenStepModal() {
      this.spinner = false
      this.updateStepObj.form = JSON.parse(this.defaultStepForm)
      this.$refs.modalStep.show()
    },
    CloseStepModal() {
      this.spinner = false
      this.updateStepObj.form = JSON.parse(this.defaultStepForm)
      this.$refs.modalStep.hide()
    },
    async UpdateStep() {
      const me = this
      this.$refs.formStep.validate(async(valid) => {
        if (valid) {
          Modal.confirm({
            title: '¿Esta seguro de concluir las operacione de contenedores vacios?',
            content: 'Esta operación es irreversible',
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            zIndex: 3000,
            async onOk () {
              me.updateStepObj.form.empty_containers_dated_at = moment().format('YYYY-MM-DD')
              me.spinner = true
              await me.PutResourcev2(me.updateStepObj, async () => {
                await me.CloseStepModal()
                me.spinner = false
                await me.FetchResource()
              })
            },
          })
        } else return false
      })
    },
    StoreEmptyContainers() {
      const me = this
      Modal.confirm({
        title: '¿Esta seguro de almacenar los contenedores vacios de la orden de producción?',
        content: 'Esta operación es irreversible',
        okText: 'Sí',
        okType: 'danger',
        cancelText: 'No',
        zIndex: 3000,
        async onOk () {
          me.fetching = true
          const formObj = {
            resourceType: `warehouseEmptyContainers/formulation/${me.formulationId}`, params: {},
          }
          await me.PostResourcev2(formObj, async () => {
            me.fetching = false
            await me.FetchResource()
          })
        },
      })
    },
    OpenStepsModal() {
      this.$refs.modalSteps.show()
    },
    CloseStepsModal() {
      this.$refs.modalSteps.show()
    },
  },
  async mounted() {
    await this.FetchResource()
    await this.FetchContainers()
  },
}
</script>
