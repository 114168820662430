<template>
  <div class="card">
    <a-row class="mt-4" style="min-height: 180px">
      <div class="col-1 offset-0">
        Aplica <a-switch @change="updateStepSlide" v-model="showTable" checked-children="Si" un-checked-children="No" />
      </div>
      <div class="card-body text-center" v-show="showTable">
        <div class="row justify-content-end" style="margin-right:10px">
          <b-button-group>
            <b-button :disabled="!enableControl" slot="avatar" variant="warning" style="margin-right:10px" pill :to="{ name: 'taxInspectionprint', params: {id: resourceObj.form.id}}">
              <a-icon type="printer" theme="twoTone" two-tone-color="#FF8000" v-b-tooltip.hover/> Imprimir
            </b-button>
            <b-button :disabled="enableControl" variant="primary" @click="Register" pill style="margin-right:10px">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar Inspección Fiscal">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar Inspección Fiscal">
                <b-icon icon="archive"/>
              </div>
            </b-button>
            <b-button :disabled="enableControl" variant="danger" @click="CloseFiscal" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Cerrar Inspección Fiscal">
                  <b-icon icon="x"/> Cerrar Inspección Fiscal
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Cerrar Inspección Fiscal">
                  <b-icon icon="x"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <a-form-model :rules="formRules" :model="resourceObj.form" ref="rule-Form" layout="inline" :wrapper-col="{ span: 24}">
          <div class="row">
            <div class="col">
              <h6 class="text-primary text-center">
                Información del cliente
              </h6>
              <hr class="bg-primary">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <a-form-model-item label="Cliente/Solicitante" ref="client_id"  prop="client_id" >
                <a-select v-model="resourceObj.form.client_id" allowClear
                        placeholder="Seleccionar Cliente" show-search :filter-option="filterOption" :disabled="enableControl">
                  <a-select-option  v-for="option in clients" :key="option.id" :value="option.id" >
                    {{option.name}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <a-form-model-item label="Responsable del cliente" ref="client_responsible"  prop="client_responsible" >
                <a-input v-model="resourceObj.form.client_responsible"
                                      style="width: 100%" :disabled="enableControl"/>
              </a-form-model-item>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h6 class="text-primary text-center">
                Etapa de recolección
              </h6>
              <hr class="bg-primary">
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Lugar de la recolección" ref="recolection_place"  prop="recolection_place" >
                    <a-input v-model="resourceObj.form.recolection_place"
                                          style="width: 100%" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Nombre de quien recibió" ref="recolection_by"  prop="recolection_by" >
                    <a-input v-model="resourceObj.form.recolection_by" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Fecha de recolección" ref="recolection_dated_at"  prop="recolection_dated_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.recolection_dated_at" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Comentarios" ref="recolection_comments"  prop="recolection_comments" >
                    <a-input v-model="resourceObj.form.recolection_comments" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
            </div>
            <div class="col-3">
              <h6 class="text-primary text-center">
                Evidencia
              </h6>
              <hr class="bg-primary">
              <a-form-model-item label="Fotos" ref="recolection_photos" prop="recolection_photos">
                <div class="clearfix">
                  <a-upload accept="image/*,.pdf" name="file"
                            :data="{'id': resourceObj.form.id, strategy:'Recolection'}"
                            :action="attachFileUrl"
                            list-type="picture-card"
                            :file-list="resourceObj.form.recolection_photos"
                            :before-upload="BeforeUpload"
                            @preview="handlePreview"
                            @change="UploadPhotos"
                            :remove="RemovePhoto" :disabled="disableImg"
                  >
                    <div>
                      <b-icon icon="plus"/>
                      <div class="ant-upload-text">Cargar Evidencia</div>
                    </div>
                  </a-upload>
                  <a-modal centered :width="1200" :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :zIndex="3000">
                    <img alt="previewFile" style="width: 100%;" :src="previewImage" />
                  </a-modal>
                </div>
              </a-form-model-item>
              <fadeAlert text="La imagen cargada es ,mayor a 2MB" variant="danger" v-model="fadeAlter"/>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h6 class="text-primary text-center">
                Etapa de recepción
              </h6>
              <hr class="bg-primary">
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Vigilante" ref="reception_vigilant"  prop="reception_vigilant" >
                    <a-input v-model="resourceObj.form.reception_vigilant"
                                          style="width: 100%" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Técnico de operaciones" ref="reception_technical_operation"  prop="reception_technical_operation" >
                    <a-input v-model="resourceObj.form.reception_technical_operation" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Fecha" ref="reception_dated_at"  prop="reception_dated_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.reception_dated_at" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Comentarios" ref="reception_comments"  prop="reception_comments" >
                    <a-input v-model="resourceObj.form.reception_comments" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
            </div>
            <div class="col-3">
              <h6 class="text-primary text-center">
                Evidencia
              </h6>
              <hr class="bg-primary">
              <a-form-model-item label="Fotos" ref="reception_photos" prop="reception_photos">
                <div class="clearfix">
                  <a-upload accept="image/*,.pdf" name="file"
                            :data="{'id': resourceObj.form.id, strategy:'Reception'}"
                            :action="attachFileUrl"
                            list-type="picture-card"
                            :file-list="resourceObj.form.reception_photos"
                            :before-upload="BeforeUpload2"
                            @preview="handlePreview2"
                            @change="UploadPhotos2"
                            :remove="RemovePhoto2" :disabled="disableImg"
                  >
                    <div>
                      <b-icon icon="plus"/>
                      <div class="ant-upload-text">Cargar Evidencia</div>
                    </div>
                  </a-upload>
                  <a-modal centered :width="1200" :visible="previewVisible2" :footer="null" @cancel="previewVisible2 = false" :zIndex="3000">
                    <img alt="previewFile" style="width: 100%;" :src="previewImage2" />
                  </a-modal>
                </div>
              </a-form-model-item>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h6 class="text-primary text-center">
                Etapa de Almacenamiento
              </h6>
              <hr class="bg-primary">
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Responsable de Almacenamiento" ref="storage_responsible"  prop="storage_responsible" >
                    <a-input v-model="resourceObj.form.storage_responsible" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Técnico de operaciones" ref="storage_technical_operation"  prop="storage_technical_operation" >
                    <a-input v-model="resourceObj.form.storage_technical_operation" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Fecha" ref="storage_dated_at"  prop="storage_dated_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.storage_dated_at" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Comentarios" ref="storage_comments"  prop="storage_comments" >
                    <a-input v-model="resourceObj.form.storage_comments" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
            </div>
            <div class="col-3">
              <h6 class="text-primary text-center">
                Evidencia
              </h6>
              <hr class="bg-primary">
              <a-form-model-item label="Fotos" ref="storage_photos" prop="storage_photos">
                <div class="clearfix">
                  <a-upload accept="image/*,.pdf" name="file"
                            :data="{'id': resourceObj.form.id, strategy:'FiscalStorage'}"
                            :action="attachFileUrl"
                            list-type="picture-card"
                            :file-list="resourceObj.form.storage_photos"
                            :before-upload="BeforeUpload3"
                            @preview="handlePreview3"
                            @change="UploadPhotos3"
                            :remove="RemovePhoto3" :disabled="disableImg"
                  >
                    <div>
                      <b-icon icon="plus"/>
                      <div class="ant-upload-text">Cargar Evidencia</div>
                    </div>
                  </a-upload>
                  <a-modal centered :width="1200" :visible="previewVisible3" :footer="null" @cancel="previewVisible3 = false" :zIndex="3000">
                    <img alt="previewFile" style="width: 100%;" :src="previewImage3" />
                  </a-modal>
                </div>
              </a-form-model-item>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h6 class="text-primary text-center">
                Etapa de Disposición
              </h6>
              <hr class="bg-primary">
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Responsable de Disposición" ref="disposal_responsible"  prop="disposal_responsible" >
                    <a-input v-model="resourceObj.form.disposal_responsible" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Proceso de destrucción" ref="disposal_process"  prop="disposal_process" >
                    <a-input v-model="resourceObj.form.disposal_process" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Fecha" ref="disposal_dated_at"  prop="disposal_dated_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.disposal_dated_at" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Comentarios" ref="disposal_comments"  prop="disposal_comments" >
                    <a-input v-model="resourceObj.form.disposal_comments" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
            </div>
            <div class="col-3">
              <h6 class="text-primary text-center">
                Evidencia
              </h6>
              <hr class="bg-primary">
              <a-form-model-item label="Fotos" ref="disposal_photos" prop="disposal_photos">
                <div class="clearfix">
                  <a-upload accept="image/*,.pdf" name="file"
                            :data="{'id': resourceObj.form.id, strategy:'FiscalDisposal'}"
                            :action="attachFileUrl"
                            list-type="picture-card"
                            :file-list="resourceObj.form.disposal_photos"
                            :before-upload="BeforeUpload4"
                            @preview="handlePreview4"
                            @change="UploadPhotos4"
                            :remove="RemovePhoto4" :disabled="disableImg"
                  >
                    <div>
                      <b-icon icon="plus"/>
                      <div class="ant-upload-text">Cargar Evidencia</div>
                    </div>
                  </a-upload>
                  <a-modal centered :width="1200" :visible="previewVisible4" :footer="null" @cancel="previewVisible4 = false" :zIndex="3000">
                    <img alt="previewFile" style="width: 100%;" :src="previewImage4" />
                  </a-modal>
                </div>
              </a-form-model-item>
            </div>
          </div>
          <div class="row">
            <div class="col-9">
              <h6 class="text-primary text-center">
                Etapa de Coprocesamiento
              </h6>
              <hr class="bg-primary">
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Responsable de Geocycle para realizar el proceso" ref="coprocess_responsible"  prop="coprocess_responsible" >
                    <a-input v-model="resourceObj.form.coprocess_responsible" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Punto de Alimentación de Coprocesamiento" ref="coprocess_storage_point"  prop="coprocess_storage_point" >
                    <a-input v-model="resourceObj.form.coprocess_storage_point" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <a-form-model-item label="Fecha" ref="coprocess_dated_at"  prop="coprocess_dated_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.coprocess_dated_at" :disabled="enableControl"/>
                  </a-form-model-item>
                </div>
                <div class="col-6">
                  <a-form-model-item label="Comentarios" ref="coprocess_comments"  prop="coprocess_comments" >
                    <a-input v-model="resourceObj.form.coprocess_comments" :disabled="enableControl"
                                          style="width: 100%"/>
                  </a-form-model-item>
                </div>
              </div>
            </div>
            <div class="col-3">
              <h6 class="text-primary text-center">
                Evidencia
              </h6>
              <hr class="bg-primary">
              <a-form-model-item label="Fotos" ref="coprocess_photos" prop="coprocess_photos">
                <div class="clearfix">
                  <a-upload accept="image/*,.pdf" name="file"
                            :data="{'id': resourceObj.form.id, strategy:'FiscalCoprocess'}"
                            :action="attachFileUrl"
                            list-type="picture-card"
                            :file-list="resourceObj.form.coprocess_photos"
                            :before-upload="BeforeUpload5"
                            @preview="handlePreview5"
                            @change="UploadPhotos5"
                            :remove="RemovePhoto5" :disabled="disableImg"
                  >
                    <div>
                      <b-icon icon="plus"/>
                      <div class="ant-upload-text">Cargar Evidencia</div>
                    </div>
                  </a-upload>
                  <a-modal centered :width="1200" :visible="previewVisible5" :footer="null" @cancel="previewVisible5 = false" :zIndex="3000">
                    <img alt="previewFile" style="width: 100%;" :src="previewImage5" />
                  </a-modal>
                </div>
              </a-form-model-item>
            </div>
          </div>
        </a-form-model>
        <div class="row">
          <div class="col-12">
            <div class="row justify-content-end align-self-center">
            </div>
          </div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'taxInspection',
  mixins: [fractalMixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      attachFileUrl: `${process.env.VUE_APP_API_URL}/attachFile`,
      fadeAlter: false,
      previewVisible: false,
      previewImage: '',
      previewVisible2: false,
      previewImage2: '',
      previewVisible3: false,
      previewImage3: '',
      previewVisible4: false,
      previewImage4: '',
      previewVisible5: false,
      previewImage5: '',
      showModal: false,
      spinnerLoad: false,
      showTable: false,
      disableImg: true,
      enableControl: false,
      client_id: undefined,
      clients: [],
      resourceObj: {
        resourceType: 'fiscalInspections',
        form: {
          id: undefined,
          formulation_id: parseInt(this.formulationId),
          // clients: {
          //   id: undefined,
          // },
          client_id: undefined,
          client_responsible: '',
          recolection_place: '',
          recolection_by: '',
          recolection_dated_at: '',
          recolection_comments: '',
          reception_vigilant: '',
          reception_technical_operation: '',
          reception_dated_at: '',
          reception_comments: '',
          storage_responsible: '',
          storage_technical_operation: '',
          storage_dated_at: '',
          storage_comments: '',
          disposal_responsible: '',
          disposal_process: '',
          disposal_dated_at: '',
          disposal_comments: '',
          coprocess_responsible: '',
          coprocess_storage_point: '',
          coprocess_dated_at: '',
          coprocess_comments: '',
          recolection_photos: [],
          reception_photos: [],
          storage_photos: [],
          disposal_photos: [],
          coprocess_photos: [],
        },
      },
      tableObj: {
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        data: [],
      },
      tableObjSteps: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
      },
      updateSteps: {
        resourceType: 'stepFormulations',
        form: {
          id: parseInt(this.formulationId),
          formulation_id: parseInt(this.formulationId),
          fiscal_inspections_dated_at: '',
          fiscal_inspections_responsible: '',
          fiscal_inspections_status: '',
          fiscal_inspections_block: false,
        },
      },
      formRules: {
        client_id: [{ required: true, message: 'El cliente/solicitante es obligatorio.', trigger: 'blur' }],
        client_responsible: [{ required: true, message: 'El responsable del cliente es obligatorio.', trigger: 'blur' }],
        recolection_place: [{ required: true, message: 'El lugar de recolección es obligatorio', trigger: 'blur' }],
        recolection_by: [{ required: true, message: 'El nombre que realizó la recolección es obligatorio', trigger: 'blur' }],
        recolection_dated_at: [{ required: true, message: 'La fecha de recolección es obligatorio', trigger: 'blur' }],
        recolection_comments: [{ required: true, message: 'Los comentarios de recolección son obligatorio', trigger: 'blur' }],
        reception_vigilant: [{ required: true, message: 'El Vigilante de recepción es obligatorio', trigger: 'blur' }],
        reception_technical_operation: [{ required: true, message: 'El Técnico de recepción es obligatorio', trigger: 'blur' }],
        reception_dated_at: [{ required: true, message: 'La fecha de recepción es obligatorio', trigger: 'blur' }],
        reception_comments: [{ required: true, message: 'Los comentarios de recepción son obligatorios', trigger: 'blur' }],
        storage_responsible: [{ required: true, message: 'El responsable de almacenamiento es obligatorio', trigger: 'blur' }],
        storage_technical_operation: [{ required: true, message: 'El técnico de almacenamiento es obligatorio', trigger: 'blur' }],
        storage_dated_at: [{ required: true, message: 'La fecha de almacenamiento es obligatorio', trigger: 'blur' }],
        storage_comments: [{ required: true, message: 'Los comentarios de almacenamiento son obligatorios', trigger: 'blur' }],
        disposal_responsible: [{ required: true, message: 'El responsable de disposición es obligatorio', trigger: 'blur' }],
        disposal_process: [{ required: true, message: 'El proceso de destrucción es obligatorio', trigger: 'blur' }],
        disposal_dated_at: [{ required: true, message: 'La fecha de disposición es obligatorio', trigger: 'blur' }],
        disposal_comments: [{ required: true, message: 'Los comentarios de disposición son obligatorios', trigger: 'blur' }],
        coprocess_responsible: [{ required: true, message: 'El responsable de Geocycle es obligatorio', trigger: 'blur' }],
        coprocess_storage_point: [{ required: true, message: 'El punto de almacenamiento de comprocesamiento es obligatorio', trigger: 'blur' }],
        coprocess_dated_at: [{ required: true, message: 'La fecha de comprocesamiento es obligatorio', trigger: 'blur' }],
        coprocess_comments: [{ required: true, message: 'Los comentarios de coprocesamiento son obligatorios', trigger: 'blur' }],
      },
    }
  },
  methods: {
    async FetchTable(pagEvent = {}) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagEvent, 'fiscalInspections', this.tableObj, params).then(
        () => {
          if (this.tableObj.data.length > 0) {
            this.resourceObj.form = this.tableObj.data[0]
            if (this.resourceObj.form.id === undefined || this.tableObjSteps.data[0].fiscal_inspections_status === 'Concluido') {
              this.disableImg = true
              this.resourceObj.form.client_id = this.resourceObj.form.clients.name
              // this.enableControl = false
            } else {
              this.disableImg = false
              // this.enableControl = true
              this.resourceObj.form.client_id = this.resourceObj.form.clients.name
              // this.client_id = parseInt(this.resourceObj.form.clients.id)
            }
          } else {
            this.disableImg = true
          }
        },
      )
    },
    onChangeSlide(checked) {
      this.showTable = !this.showTable
    },
    SaveAll() {
    },
    async CloseFiscal() {
      this.closeCrushingMessage('Esta acción concluirá el proceso de Inspección Fiscal. ¿Desea Continuar?')
    },
    async Register() {
      let array = []
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (!this.resourceObj.form.id) {
            this.resourceObj.form.client_id = parseInt(this.resourceObj.form.client_id)
            // delete this.resourceObj.form.clients
            delete this.resourceObj.form.id
            this.resourceObj.form.recolection_dated_at = moment(this.resourceObj.form.recolection_dated_at).format('YYYY-MM-DD HH:mm')
            this.resourceObj.form.reception_dated_at = moment(this.resourceObj.form.reception_dated_at).format('YYYY-MM-DD HH:mm')
            this.resourceObj.form.storage_dated_at = moment(this.resourceObj.form.storage_dated_at).format('YYYY-MM-DD HH:mm')
            this.resourceObj.form.disposal_dated_at = moment(this.resourceObj.form.disposal_dated_at).format('YYYY-MM-DD HH:mm')
            this.resourceObj.form.coprocess_dated_at = moment(this.resourceObj.form.coprocess_dated_at).format('YYYY-MM-DD HH:mm')
            await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else {
            array = this.resourceObj
            if (array.form.clients) {
              delete array.form.clients
            }
            if (array.form.coprocess_photos) {
              delete array.form.coprocess_photos
            }
            if (array.form.disposal_photos) {
              delete array.form.disposal_photos
            }
            if (array.form.reception_photos) {
              delete array.form.reception_photos
            }
            if (array.form.recolection_photos) {
              delete array.form.recolection_photos
            }
            if (array.form.storage_photos) {
              delete array.form.storage_photos
            }
            if (array.form.updated_at) {
              delete array.form.updated_at
            }
            if (array.form.deleted_at || array.form.deleted_at === null) {
              delete array.form.deleted_at
            }
            if (array.form.created_at) {
              delete array.form.created_at
            }
            if (array.form.formulations) {
              delete array.form.formulations
            }
            array.form.client_id = parseInt(array.form.client_id)
            array.form.recolection_dated_at = moment(array.form.recolection_dated_at).format('YYYY-MM-DD HH:mm')
            array.form.reception_dated_at = moment(array.form.reception_dated_at).format('YYYY-MM-DD HH:mm')
            array.form.storage_dated_at = moment(array.form.storage_dated_at).format('YYYY-MM-DD HH:mm')
            array.form.disposal_dated_at = moment(array.form.disposal_dated_at).format('YYYY-MM-DD HH:mm')
            array.form.coprocess_dated_at = moment(array.form.coprocess_dated_at).format('YYYY-MM-DD HH:mm')
            await this.PutResourcev2(array, this.SuccessCallback, () => { this.spinnerLoad = false })
          }
        } else return false
      })
    },
    closeSampleModal() {
      this.showModal = false
    },
    async SendSolid() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.closeSampleModal()
    },
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/clients',
          params: params1,
        },
      ]
      const [clients] = await this.GetConcurrencyResources(arr)
      this.clients = clients
    },
    BeforeUpload(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        if (!this.resourceObj.form.recolection_photos) {
          this.resourceObj.form.recolection_photos = []
        }
        this.resourceObj.form.recolection_photos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.recolection_photos.slice(-2)
        return true
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async RemovePhoto(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'Recolection',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.recolection_photos = resp.photos
        // await this.getData()
        await this.FetchTable()
      })
    },
    async UploadPhotos(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response')) {
        this.resourceObj.form.recolection_photos = file.response.photos
        await this.FetchTable()
      }
    },
    BeforeUpload2(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        if (!this.resourceObj.form.reception_photos) {
          this.resourceObj.form.reception_photos = []
        }
        this.resourceObj.form.reception_photos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.reception_photos.slice(-2)
        return true
      }
    },
    async handlePreview2(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage2 = file.url || file.preview
      this.previewVisible2 = true
    },
    async RemovePhoto2(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'Reception',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.reception_photos = resp.photos
        await this.FetchTable()
      })
    },
    async UploadPhotos2(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response')) {
        this.resourceObj.form.reception_photos = file.response.photos
        await this.FetchTable()
      }
    },
    BeforeUpload3(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        if (!this.resourceObj.form.storage_photos) {
          this.resourceObj.form.storage_photos = []
        }
        this.resourceObj.form.storage_photos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.storage_photos.slice(-2)
        return true
      }
    },
    async handlePreview3(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage3 = file.url || file.preview
      this.previewVisible3 = true
    },
    async RemovePhoto3(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'FiscalStorage',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.storage_photos = resp.photos
        await this.FetchTable()
      })
    },
    async UploadPhotos3(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response')) {
        this.resourceObj.form.storage_photos = file.response.photos
        await this.FetchTable()
      }
    },
    BeforeUpload4(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        if (!this.resourceObj.form.disposal_photos) {
          this.resourceObj.form.disposal_photos = []
        }
        this.resourceObj.form.disposal_photos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.disposal_photos.slice(-2)
        return true
      }
    },
    async handlePreview4(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage4 = file.url || file.preview
      this.previewVisible4 = true
    },
    async RemovePhoto4(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'FiscalDisposal',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.disposal_photos = resp.photos
        await this.FetchTable()
      })
    },
    async UploadPhotos4(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response')) {
        this.resourceObj.form.disposal_photos = file.response.photos
        await this.FetchTable()
      }
    },
    BeforeUpload5(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        if (!this.resourceObj.form.coprocess_photos) {
          this.resourceObj.form.coprocess_photos = []
        }
        this.resourceObj.form.coprocess_photos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.coprocess_photos.slice(-2)
        return true
      }
    },
    async handlePreview5(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage4 = file.url || file.preview
      this.previewVisible4 = true
    },
    async RemovePhoto5(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'FiscalCoprocess',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.coprocess_photos = resp.photos
        await this.FetchTable()
      })
    },
    async UploadPhotos5(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response')) {
        this.resourceObj.form.coprocess_photos = file.response.photos
        await this.FetchTable()
      }
    },
    async FetchSteps(pagEvent = {}) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagEvent, 'stepFormulations', this.tableObjSteps, params)
      if (this.tableObjSteps.data.length > 0) {
        this.showTable = Boolean(Number(this.tableObjSteps.data[0].fiscal_inspections_block))
        if (this.tableObjSteps.data[0].fiscal_inspections_status === 'Concluido') {
          this.enableControl = true
          this.disableImg = true
        } else {
          this.enableControl = false
          this.disableImg = false
        }
        this.updateSteps.form.id = parseInt(this.formulationId)
        this.updateSteps.form.formulation_id = this.formulationId
        this.updateSteps.form.fiscal_inspections_dated_at = ''
        this.updateSteps.form.fiscal_inspections_responsible = ''
        this.updateSteps.form.fiscal_inspections_status = this.tableObjSteps.data[0].fiscal_inspections_status
        this.updateSteps.form.fiscal_inspections_block = this.tableObjSteps.data[0].fiscal_inspections_block
      }
    },
    async updateStepSlide() {
      this.updateSteps.form.fiscal_inspections_block = this.showTable
      this.updateSteps.form.fiscal_inspections_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
      if (this.showTable) {
        this.updateSteps.form.fiscal_inspections_status = 'Pendiente'
      } else {
        this.updateSteps.form.fiscal_inspections_status = 'No Aplica'
      }
      await this.PutResourcev2(this.updateSteps, this.SuccessCallback, () => { this.spinnerLoad = false })
    },
    closeCrushingMessage(message) {
      Modal.confirm({
        title: '¡Concluyendo proceso de Inspección Fiscal!',
        content: message,
        okText: 'Ok',
        cancelText: 'Cancelar',
        centered: true,
        zIndex: 3000,
        onOk: () => { this.updateStep() },
      })
    },
    async updateStep() {
      this.updateSteps.form.fiscal_inspections_status = 'Concluido'
      this.updateSteps.form.fiscal_inspections_block = true
      this.updateSteps.form.fiscal_inspections_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
      await this.PutResourcev2(this.updateSteps, this.SuccessCallback, () => { this.spinnerLoad = false })
      if (this.updateSteps.form.fiscal_inspections_status === 'Concluido') {
        this.enableControl = true
        this.disableImg = true
      } else {
        this.enableControl = false
        this.disableImg = false
      }
      await this.FetchSteps()
    },
  },
  async mounted() {
    await this.FetchSteps()
    await this.fetchCombos()
    await this.FetchTable()
  },
}
</script>
