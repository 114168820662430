<template>
  <div class="card">
    <div class="card-body text-center">
      <div class="col-2 offset-10">
        <responsive-button :block="true"
                  variant="danger"
                  responsive="sm"
                  icon="dash-circle"
                  :text="'Paro de Trituración'"
                  @ClickEvent="openAddCrushingModal"
                  pill :disabled="disableControls"
        />
      </div>
      <div class="row">
        <div class="col-12">
          <h6 class="text-primary text-center">
            Listado de paros registrados
          </h6>
          <hr class="bg-primary">
          <a-table :columns="tableObj.columns"
             :data-source="tableObj.data"
             :pagination="tableObj.pagination"
             :loading="tableObj.loading"
             @change="FetchTable"
             :row-key="record => record.id"
          >
            <template slot="crushingType" slot-scope="record">
              <small>
                <strong>
                  {{ record.auxiliar_masters.code }} - {{ record.auxiliar_masters.name }}
                </strong>
              </small>
            </template>
            <template slot="stopType" slot-scope="record">
              <small>
                <strong>
                  {{ record.operation_stop_reasons.description }}
                </strong>
              </small>
            </template>
            <template slot="stopCause" slot-scope="record">
              <small>
                <strong>
                  {{ record.operation_stop_detail }}
                </strong>
              </small>
            </template>
            <template slot="duration" slot-scope="record">
              <small>
                <strong>
                  {{ (record.is_finished == "0") ? '' : Number(record.operation_stop_duration).toFixed(2) }}
                </strong>
              </small>
            </template>
            <template slot="stopObservations" slot-scope="record">
              <small>
                <strong>
                  {{ (record.comments) ? record.comments : '' }}
                </strong>
              </small>
            </template>
            <template slot="dateStart" slot-scope="record">
              <!-- <div class="row font-weight-bold">
                <div class="col-12 text-center">
                  Fecha:
                </div>
              </div> -->
              <small>
                <strong>
                  {{ moment.utc(record.operation_stop_started_at).format('YYYY-MM-DD hh:mm a') }}
                </strong>
              </small>
            </template>
            <template slot="dateEnd" slot-scope="record">
              <!-- <div class="row font-weight-bold">
                <div class="col-12 text-center">
                  Fecha:
                </div>
              </div> -->
              <small>
                <strong>
                  {{ (record.is_finished == "0") ? '' : moment.utc(record.operation_stop_finished_at).format('YYYY-MM-DD hh:mm a') }}
                </strong>
              </small>
            </template>
            <template slot="action" slot-scope="data">
              <div>
                <b-button variant="warning"
                          @click="openCloseCrushingModal(data)"
                          pill :disabled="disableControls"
                >
                  <div class="d--none"
                      v-b-tooltip.hover
                      title="Editar"
                  >
                    <b-icon icon="pencil-square"/>
                  </div>
                </b-button>
              </div>
            </template>
          </a-table>
          <div class="row justify-content-end align-self-center">
            <b-modal size="lg"
            :title="(whOperation === true) ? 'Registro de trituradoras' : 'Concluir Paro'"
            v-model="showModal" ref="modal-Form"
            >
              <my-spinner :load="spinnerLoad"/>
              <b-container fluid v-show="!spinnerLoad">
                <a-form-model v-if="whOperation === true" :rules="formRules" :model="resourceObj.form" ref="rule-Form"
                    layout="vertical" :wrapper-col="{ span: 24}">
                  <a-form-model-item label="Trituradoras" ref="auxiliar_master_id"  prop="auxiliar_master_id" >
                    <a-select v-model="resourceObj.form.auxiliar_master_id" allowClear
                            placeholder="Seleccionar tipo de Trituradora" show-search>
                      <a-select-option  v-for="option in crushings" :key="option.id" :value="option.id" >
                        {{option.code}}-{{option.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item class="col-md-12" label="Fecha y hora de inicio de paro" ref="operation_stop_started_at"  prop="operation_stop_started_at" >
                      <a-date-picker show-time placeholder="Seleccionar fecha y hora de inicio" style="width:100%" v-model="resourceObj.form.operation_stop_started_at"/>
                  </a-form-model-item>
                  <a-form-model-item label="Tipo de Paro" ref="operation_stop_reason_id"  prop="operation_stop_reason_id" >
                    <a-select v-model="resourceObj.form.operation_stop_reason_id" allowClear
                            placeholder="Seleccionar tipo de paro" show-search>
                      <a-select-option  v-for="option in stopReasons" :key="option.id" :value="option.id" >
                        {{option.description}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="Motivo de paro:"
                                        class="mx-1"
                                        ref="operation_stop_detail"
                                        prop="operation_stop_detail"
                  >
                    <a-textarea
                      v-model="resourceObj.form.operation_stop_detail"
                      placeholder="Observaciones"
                      :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                  </a-form-model-item>
                  <div class="row justify-content-end">
                    <b-button-group>
                      <b-button  variant="primary" @click="SendCrushingControl" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/> Guardar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/>
                        </div>
                      </b-button>
                    </b-button-group>
                  </div>
                </a-form-model>
                <a-form-model v-else :model="resourceObj.form" ref="rule-Form1" :rules="formRules1"
                    layout="vertical" :wrapper-col="{ span: 24}">
                  <a-form-model-item label="Fecha y hora de fin de paro" ref="operation_stop_finished_at"  prop="operation_stop_finished_at" >
                    <a-date-picker show-time placeholder="Seleccionar fecha" style="width:100%" v-model="resourceObj.form.operation_stop_finished_at"/>
                  </a-form-model-item>
                  <a-form-model-item label="Observaciones:"
                                        class="mx-1"
                                        ref="ocomments"
                                        prop="output_comments"
                  >
                    <a-textarea
                      v-model="resourceObj.form.comments"
                      placeholder="Observaciones"
                      :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                  </a-form-model-item>
                  <div class="row justify-content-end">
                    <b-button-group>
                      <b-button  variant="primary" @click="StopCrushing" pill>
                        <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                          <b-icon icon="archive"/> Guardar
                        </div>
                        <div class="d-md-none" v-b-tooltip.hover title="Guardar">2
                          <b-icon icon="archive"/>
                        </div>
                      </b-button>
                    </b-button-group>
                  </div>
                </a-form-model>
              </b-container>
              <template v-slot:modal-footer>
                <simple-modal-footer @ClickEvent="closeSampleModal"/>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import moment from 'moment'
import { Modal } from 'ant-design-vue'
export default {
  name: 'detailTab',
  mixins: [fractalMixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
    steps: {
      type: [Object],
      required: false,
    },
  },
  data() {
    return {
      stopReasons: [],
      crushings: [],
      filterCrushing: '',
      showModal: false,
      whOperation: false,
      spinnerLoad: false,
      disableControls: false,
      resourceObj: {
        resourceType: 'controlCrushings',
        form: {
          id: '',
          formulation_id: '',
          auxiliar_master_id: '',
          operation_stop_started_at: '',
          operation_stop_finished_at: '',
          operation_stop_reason_id: '',
          operation_stop_detail: '',
          operation_stop_duration: '',
          comments: '',
        },
      },
      updateSteps: {
        resourceType: 'stepFormulations',
        form: {
          id: '',
          formulation_id: undefined,
          crushings_dated_at: '',
          crushings_responsible: '',
          crushings_status: '',
          crushings_block: false,
        },
      },
      formRules: {
        auxiliar_master_id: [{ required: true, message: 'La trituradora es obligatoria.', trigger: 'blur' }],
        operation_stop_started_at: [{ required: true, message: 'La fecha y hora de inicio es obligatorio.', trigger: 'blur' }],
        operation_stop_reason_id: [{ required: true, message: 'El tipo de paro es obligatorio', trigger: 'blur' }],
      },
      formRules1: {
        operation_stop_finished_at: [{ required: true, message: 'La fecha y hora de paro es obligatoria.', trigger: 'blur' }],
        // output_comments: [{ required: true, message: 'L es obligatoria.', trigger: 'blur' }],
      },
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Trituradora',
            dataIndex: '',
            class: 'text-center',
            key: 'crushingType',
            scopedSlots: { customRender: 'crushingType' },
            width: '14%',
          },
          {
            title: 'Tipo de Paro',
            dataIndex: '',
            class: 'text-center',
            key: 'stopType',
            scopedSlots: { customRender: 'stopType' },
            width: '11%',
          },
          {
            title: 'Motivo de Paro',
            dataIndex: '',
            class: 'text-center',
            key: 'stopCause',
            scopedSlots: { customRender: 'stopCause' },
            width: '14%',
          },
          {
            title: 'Hora de Inicio de Paro',
            dataIndex: '',
            class: 'text-center',
            key: 'dateStart',
            scopedSlots: { customRender: 'dateStart' },
          },
          {
            title: 'Hora de Fin de Paro',
            dataIndex: '',
            class: 'text-center',
            key: 'dateEnd',
            scopedSlots: { customRender: 'dateEnd' },
          },
          {
            title: 'Duración paro',
            dataIndex: '',
            class: 'text-center',
            key: 'duration',
            scopedSlots: { customRender: 'duration' },
          },
          {
            title: 'Observaciones paro',
            dataIndex: '',
            class: 'text-center',
            key: 'stopObservations',
            scopedSlots: { customRender: 'stopObservations' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            width: '7%',
          },
        ],
      },
    }
  },
  methods: {
    async openCloseCrushingModal(data) {
      this.spinnerLoad = true
      this.whOperation = false
      this.showModal = true
      this.spinnerLoad = false
      this.resourceObj.form = {
        id: data.id,
        formulation_id: parseInt(this.formulationId),
        auxiliar_master_id: parseInt(data.auxiliar_master_id),
        operation_stop_started_at: data.operation_stop_started_at,
        operation_stop_finished_at: '',
        operation_stop_reason_id: parseInt(data.operation_stop_reason_id),
        operation_stop_detail: data.operation_stop_detail,
        comments: '',
        operation_stop_duration: 0.0,
        is_finished: '1',
      }
    },
    async openAddCrushingModal() {
      this.spinnerLoad = true
      this.whOperation = true
      this.showModal = true
      await this.fetchCombos().then(
        () => {
          this.spinnerLoad = false
          this.resourceObj.form = {
            formulation_id: parseInt(this.formulationId),
            auxiliar_master_id: undefined,
            operation_stop_started_at: '',
            operation_stop_finished_at: moment.utc(),
            operation_stop_reason_id: undefined,
            operation_stop_detail: '',
            operation_stop_duration: 0,
            comments: '',
          }
        },
      )
    },
    async FetchTable(pagEvent = {}) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagEvent, 'controlCrushings', this.tableObj, params)
    },
    async SendCrushingControl() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.resourceObj.form.operation_stop_started_at = moment(this.resourceObj.form.operation_stop_started_at).format('YYYY-MM-DD HH:mm')
          this.spinnerLoad = true
          await this.PostResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async StopCrushing() {
      const diff = moment.duration(moment(this.resourceObj.form.operation_stop_finished_at).diff(moment(this.resourceObj.form.operation_stop_started_at), 'minutes'))
      const minut = diff.asMilliseconds()
      if (diff < 0) {
        this.errorMessage('Ingrese una fecha final de fin de paro,.')
        return 0
      } else {
        this.resourceObj.form.operation_stop_duration = Number(minut)
      }
      this.$refs['rule-Form1'].validate(async(valid) => {
        if (valid) {
          this.resourceObj.form.operation_stop_finished_at = moment(this.resourceObj.form.operation_stop_finished_at).format('YYYY-MM-DD HH:mm')
          this.spinnerLoad = true
          await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async openAddStopModal(data = {}) {
      this.showModal = true
      this.whOperation = true
      this.spinnerLoad = true
      this.resourceObj.form = {
        id: data.id,
      }
      this.spinnerLoad = false
    },
    async openEndStopModal(data = {}) {
      await this.fetchCombos()
      this.showModal = true
      this.whOperation = true
      this.spinnerLoad = true
      this.spinnerLoad = false
    },
    closeSampleModal(value) {
      this.showModal = value
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    CloseModal() {
      this.showModal = false
    },
    async fetchCombos() {
      const arr = [
        {
          path: '/operationStopReasons',
        },
        {
          path: '/fetch/auxiliarMasters?',
        },
      ]
      const [stopReasons, crushings] = await this.GetConcurrencyResources(arr)
      this.stopReasons = stopReasons
      this.crushings = crushings
    },
    checkSteps() {
      if (this.steps.data.length > 0) {
        if (this.steps.data[0].crushings_status === 'Concluido') {
          this.disableControls = true
        } else {
          this.disableControls = false
        }
        this.updateSteps.form.id = this.formulationId
        this.updateSteps.form.crushings_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
        this.updateSteps.form.crushings_responsible = this.steps.data[0].crushings_responsible
        this.updateSteps.form.crushings_status = this.steps.data[0].crushings_status
        this.updateSteps.form.crushings_block = this.steps.data[0].crushings_block
      }
    },
    upfield(data) {
      this.disableControls = true
    },
    errorMessage(message) {
      Modal.error({
        title: '¡Ha ocurrido un error!',
        content: message,
        okText: 'Ok',
        centered: true,
        zIndex: 3000,
      })
    },
  },
  async mounted() {
    await this.FetchTable()
    this.checkSteps()
  },
}
</script>
