<template>
  <div>
    <div class="air__utils__heading"> <h5>Orden de Producción</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <a-row align="middle" class="bg-white mb-2 py-3 mx-n2">
      <productionOrderForm :id="id"/>
    </a-row>
    <a-row class="mt-4" v-if="id">
      <a-tabs defaultActiveKey="1" type="card" size="large">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="calendar" /> Residuos
          </span>
          <div class="card">
            <div class="card-body text-center">
              <materialTab :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="experiment" />Pruebas de Compatibilidad
          </span>
          <div class="card">
            <div class="card-body text-center">
              <compatibilityTests :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">
            <a-icon type="delete" />Registro de Tambos Vacios
          </span>
          <div class="card">
            <div class="card-body text-center">
              <emptyContainers :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">
            <a-icon type="funnel-plot" />Control de Trituradora
          </span>
          <div class="card">
            <div class="card-body text-center">
              <control-trituradora :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="5">
          <span slot="tab">
            <a-icon type="reconciliation" />Resumen de Producción
          </span>
          <div class="card">
            <div class="card-body text-center">
              <production-summary :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="6">
          <span slot="tab">
            <a-icon type="file-search" />Inspección Fiscal
          </span>
          <div class="card">
            <div class="card-body text-center">
              <tax-inspection :formulation-id="id"/>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-row>
  </div>
</template>

<script>
import productionOrderForm from './form'
import materialTab from '../../formulations/components/production_order_scheduling_tabs/material-tab.vue'
import compatibilityTests from './compatibility_tests.vue'
import emptyContainers from './empty_containers.vue'
import controlTrituradora from '@/views/geocycle/operations/operation/production_order/controlTrituradora'
import taxInspection from '@/views/geocycle/operations/operation/production_order/taxInspection'
import productionSummary from '@/views/geocycle/operations/operation/production_order/productionSummary'
export default {
  name: 'operationProcessList',
  components: {
    productionOrderForm,
    materialTab,
    compatibilityTests,
    emptyContainers,
    controlTrituradora,
    productionSummary,
    taxInspection,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'operations',
          breadcrumbName: 'Operaciones',
        },
        {
          name: 'operation_process',
          breadcrumbName: 'Proceso Operativo',
        },
        {
          breadcrumbName: 'Órdenes de Produción',
          name: 'production_orders',
          aIcon: '',
        },
        {
          breadcrumbName: 'Programación de Órdenes de Produción',
          aIcon: '',
        },
      ],
      // Id from the order
      id: this.$route.params.id,
    }
  },
}
</script>
