<template>
  <div class='card'>
    <a-row class="mt-4" style="min-height: 180px">
      <div class="col-1 offset-0">
        Aplica <a-switch @change="updateStep" v-model="showTable" checked-children="Si" un-checked-children="No" />
      </div>
      <a-tabs defaultActiveKey="1" v-show="showTable">
        <a-tab-pane key="1">
          <span slot="tab">
            <!-- <a-icon type="calendar" /> -->
            Resumen
          </span>
          <resumen-tab :formulation-id="formulationId" :steps="tableObj" @onChangeValue="UpdateTables"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <!-- <a-icon type="calendar" /> -->
            Detalle
          </span>
          <detail-tab ref="upd" :formulation-id="formulationId" :steps="tableObj"/>
        </a-tab-pane>
      </a-tabs>
      <!-- <a-tabs defaultActiveKey="1" type="card" size="large">
      </a-tabs> -->
    </a-row>
  </div>
</template>

<script>
// import { fractalMixin } from '@/mixins/fractalMixin'
import resumenTab from '@/views/geocycle/operations/operation/production_order/trituradoras/resumenTab'
import moment from 'moment'
import detailTab from '@/views/geocycle/operations/operation/production_order/trituradoras/detailTab'
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'controlTrituradora',
  mixins: [fractalMixin],
  props: {
    formulationId: {
      type: [String, Number],
      required: false,
    },
  },
  components: { detailTab, resumenTab },
  data() {
    return {
      updateSteps: {
        resourceType: 'stepFormulations',
        form: {
          id: parseInt(this.formulationId),
          formulation_id: parseInt(this.formulationId),
          crushings_dated_at: '',
          crushings_responsible: '',
          crushings_status: '',
          crushings_block: false,
        },
      },
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
      },
      showTable: false,
    }
  },
  methods: {
    onChangeSlide(checked) {
      this.showTable = !this.showTable
    },
    openCloseCrushingModal() {
    },
    async FetchSteps(pagEvent = {}) {
      const params = {
        formulationId: this.formulationId,
      }
      await this.GetTableResourcev2(pagEvent, 'stepFormulations', this.tableObj, params)
      this.showTable = Boolean(Number(this.tableObj.data[0].crushings_block))
    },
    async updateStep() {
      this.updateSteps.form.crushings_block = this.showTable
      this.updateSteps.form.crushings_dated_at = moment.utc().format('YYYY-MM-DD HH:mm')
      if (this.showTable) {
        this.updateSteps.form.crushings_status = 'Pendiente'
      } else {
        this.updateSteps.form.crushings_status = 'No Aplica'
      }
      await this.PutResourcev2(this.updateSteps, this.SuccessCallback, () => { this.spinnerLoad = false })
    },
    async UpdateTables() {
      await this.FetchSteps()
    },
  },
  async mounted() {
    await this.FetchSteps()
  },
}
</script>
